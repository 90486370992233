/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken, stripHtml, capitalize } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useParams } from 'react-router-dom';
// Assets
import TeclaLogo from 'assets/images/logos/Tecla_Logo_bg.png'
// React Player
import ReactPlayer from "react-player";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useMediaQuery from "@mui/material/useMediaQuery"
import CircularProgress from '@mui/material/CircularProgress'
// assets
import GitlabIcon from "assets/images/socialIcons/Gitlab.png";
import PortfolioIcon from "assets/images/socialIcons/Portfolio.png";
import stackOverflowIcon from "assets/images/socialIcons/StackO.png";
import WebsiteIcon from "assets/images/socialIcons/Website.png";
import emptyCVImg from "assets/images/illustrations/recommended.png";
import placeholder from "assets/images/placeholder.jpg"
import leverIcon from "assets/images/LeverIcon.png"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import ProfileSetInfoCard from "examples/Cards/InfoCards/ProfileSetInfoCard";
import SkillsUpdateList from "examples/Lists/SkillsUpdateList";
import SkillsList from "examples/Lists/SkillsList";
import InterestList from "examples/Lists/InterestList";
import ApplicationDetails from "examples/Lists/ApplicationList";
import Appications from "examples/Lists/Appications";
import ScreeningProcessPanel from "examples/Lists/ScreeningProcessPanel";
import ApplicationBlasted from "examples/Lists/ApplicationBlasted";
// Overview page components
import Header from "layouts/pages/profile/components/Header";
// PDF
import PdfViewer from "layouts/pdfViewer/PdfViewer"
import ApplicationNotesModal from "./components/applicationNotesModal/ApplicationsNotesModal";

function ApplicationOverviewRecruiter() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const user = ReactSession.get("user");
  const recruiter = ReactSession.get("recruiter");

  const [application, setApplication] = useState({});
  const [recruiters, setRecruiters] = useState([]);
  const [notes, setNotes] = useState([]);
  const [updateNotes, setUpdateNotes] = useState(0);
  const [appCandidateId, setAppCandidateId] = useState(0);
  const [showToClient, setShowToClient] = useState(false);
  const [activities, setActivities] = useState([]);

  const [isFetchingApplicationData, setIsFetchingApplicationData] = useState(false);

  const [candidate, setCandidate] = useState(application.candidate || {})

  let canShare = true;
  const { appId } = useParams();
  if (appId) {
    canShare = false;
  }

  function createData(id, name, date, message, recruitersText, recruiterAvatar, authorId, recruitersData) {
    return { id, name, date, message, recruitersText, recruiterAvatar, authorId, recruitersData };
  }

  function getRecruiters() {
    const headers = { "Content-Type": "application/json" };
    const recruiterUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/getRecruiters/`;
    headers.Authorization = `Token ${token}`;
    fetch(recruiterUrl, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const users = [];
          // eslint-disable-next-line
          for (const user of data.data) {
            users.push({
              id: user.id,
              display: user.uname,
              uname: `@${user.uname}`,
              checked: false
            });
          }

          setRecruiters(users);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);

  function getStringDate(date) {
    return `${
      padL(date.getMonth()+1)}/${
      padL(date.getDate())}/${
      date.getFullYear()} - ${
      padL(date.getHours())}:${
      padL(date.getMinutes())
    }`
  }

  function getNotes(userId) {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const url = `${process.env.REACT_APP_API_ROUTE}/api/notes/getNotes/?candidate=${Number(userId)}`;
    headers.Authorization = `Token ${token}`;
    fetch(`${url}`, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const tempDefaultNotes = [];
          let noteDate = "";
          let recruitersText = "";
          if (data.data && data.data.length) {
            // eslint-disable-next-line
            for (const note of data.data) {
              recruitersText = "";
              // eslint-disable-next-line
              for (const recruiter of note.recruiters) {
                recruitersText = `${recruitersText}${recruiter.user.first_name} ${recruiter.user.last_name}, `
              }
              if (recruitersText.length) {
                recruitersText = recruitersText.substring(0, recruitersText.length - 2);
              }

              noteDate = getStringDate(new Date(note.createdAt));
              tempDefaultNotes.push(
                createData(
                  note.id,
                  `${note.author.first_name} ${note.author.last_name}`,
                  noteDate,
                  note.content,
                  recruitersText,
                  `${process.env.REACT_APP_API_ROUTE}${note.author.photo}`,
                  note?.author?.id,
                  note.recruiters
                )
              );
            }
          }
          $(".moonLoaderBox").removeClass("active");

          if (tempDefaultNotes.length) {
            setNotes(tempDefaultNotes);
            const searchParams = new URLSearchParams(window.location.search);
            const urlNotes = searchParams.get('notes');
            if (urlNotes && Number(urlNotes) === 1) {
              let timerid = "";
              if (timerid) {
                clearTimeout(timerid);
              }
              timerid = setTimeout(() => {
                $("button:contains('Notes')").click();
              }, 500);
            }
          }
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getActivities(userId) {
    const headers = { "Content-Type": "application/json" };
    const recruiterUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${userId}/getCandidateActivity/`;
    headers.Authorization = `Token ${token}`;
    fetch(recruiterUrl, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          setActivities(data.data);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    if (appCandidateId) {
      getNotes(appCandidateId);
    }
  }, [updateNotes]);

  useEffect(() => {
    setIsFetchingApplicationData(true)

    const headers = { "Content-Type": "application/json" };
    if (appId || token) {
      const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${appId}/companyApplicationDetail/`;
      headers.Authorization = `Token ${token}`;
      fetch(url, { headers, })
        .then(async response => {
          const data = await response.json();
          if (data.success) {
            const newApplication = { ...data.data };
            const candidateId = newApplication.user.id;
            const newCandidate = { ...data.data.user.candidate };
            newApplication.user = {};
            newCandidate.user = data.data.user;
            if (appId) {
              newCandidate.isParameter = true;
            }
            if (data.data.cv) {
              newCandidate.cv = data.data.cv;
            } else if (data.data.user.candidate.cvCreated) {
              newCandidate.cv = data.data.user.candidate.cvCreated;
            }
            newApplication.candidate = newCandidate;
            newApplication.candidate.forms = data?.data?.forms;
            newApplication.candidate.formsCount = data?.data?.formsCount;

            const cfirstName = newCandidate.user ? newCandidate.user.first_name : "";
            const clastName = newCandidate.user ? newCandidate.user.last_name : "";
            document.title = `Application - ${cfirstName} ${clastName} | TECLA`;
            setApplication(newApplication);
            setCandidate(newApplication?.candidate)
            setShowToClient(newApplication.showToClient);
            if (newApplication && candidateId && token) {
              getNotes(candidateId);
              setAppCandidateId(candidateId)
              getActivities(candidateId);
              getRecruiters();
            }

            ReactSession.set("CandidateSalaryExpectation", newApplication.salary)
            ReactSession.set("CandidateForEdit", newCandidate)
            ReactSession.set("ApplicationForEdit", data.data);

            setIsFetchingApplicationData(false)
          } else {
            window.location.replace("/");
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);

  const firstName = candidate.user ? candidate.user.first_name : "";
  const lastName = candidate.user ? candidate.user.last_name : "";
  const country = candidate.country ? candidate.country.name : "";

  let dateOfBirth = candidate.dateOfBirth ? candidate.dateOfBirth : "";
  if (dateOfBirth) {
    dateOfBirth = new Date(`${dateOfBirth}T00:00:00`);
    let month = dateOfBirth.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = dateOfBirth.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    dateOfBirth = `${month}/${day}`;
  }

  const profileUser = {
    fullName: `${firstName} ${lastName}`,
    location: country,
    birthday: dateOfBirth,
    mobile: candidate.phone,
    email: application?.email
  }

  const githubLink = candidate.github ? candidate.github : false;
  const gitLabLink = candidate.gitLab ? candidate.gitLab : false;
  const websiteLink = candidate.website ? candidate.website : false;
  const stackOverflowLink = candidate.stackOverflow ? candidate.stackOverflow : false;
  const portfolioLink = candidate.portfolio ? candidate.portfolio : false;
  const facebookLink = candidate.facebook ? candidate.facebook : false;
  const twitterLink = candidate.twitter ? candidate.twitter : false;
  const linkedinLink = candidate.linkedin ? candidate.linkedin : false;
  const instagramLink = candidate.instagram ? candidate.instagram : false;
  const videoLink = candidate.videoUrl ? candidate.videoUrl : false;
  const leverLink = candidate.leverUrl ? candidate.leverUrl : false;

  let cv
  if (candidate.cv) cv = `${process.env.REACT_APP_API_ROUTE}${candidate.cv}`;

  function getSkillValue(skillValue) {
    if (skillValue >= 5) {
      return 100;
    }

    return skillValue * 20;
  }

  const skillsetListData = [];
  if (candidate.skills) {
    let levelYears = "";
    // eslint-disable-next-line
    for (let i = 0; i < candidate.skills.length; i++) {
      levelYears = `${candidate.skills[i].level} Year`;
      if (candidate.skills[i].level >= 10) {
        levelYears = `${candidate.skills[i].level} Years+`;
      } else if (candidate.skills[i].level > 1) {
        levelYears = `${candidate.skills[i].level} Years`;
      } else if (candidate.skills[i].level === 0) {
        levelYears = "";
      }
      skillsetListData.push({
        image: placeholder,
        name: candidate.skills[i].skill.name,
        percent: levelYears,
        color: "info",
        value: getSkillValue(candidate.skills[i].level)
      });
    }
  }

  const englishsetListData = [];
  let comment = "Not able to speak English at all";
  if (candidate.englishLevel) {
    englishsetListData.push({
      image: placeholder,
      name: "Spoken english",
      percent: `${candidate.englishLevel}0%`,
      color: "info",
      value: candidate.englishLevel * 10,
      englishValidator: candidate.englishValidator,
    });
    if (candidate.englishLevel === 10) {
      comment = "English is like my first language";
    } else if (candidate.englishLevel === 9) {
      comment = "Excellent English; no issues at all";
    } else if (candidate.englishLevel === 8) {
      comment = "I can hold a conversation easily; minor mistakes";
    } else if (candidate.englishLevel === 7) {
      comment = "I can hold a conversation with a few mistakes";
    } else if (candidate.englishLevel === 6) {
      comment = "I can speak but with lots of mistakes";
    } else if (candidate.englishLevel === 5) {
      comment = "Currently learning; not able to speak yet";
    }
  }

  const interestListData = [];
  if (candidate.industries) {
    // eslint-disable-next-line
    for (let i = 0; i < candidate.industries.length; i++) {
      interestListData.push({
        image: placeholder,
        name: candidate.industries[i].name,
      });
    }
  }

  const appsListData = [];
  if (application.applications) {
    let appName = "";
    let job = {};
    // eslint-disable-next-line
    for (let i = 0; i < application.applications.length; i++) {
      job = application.applications[i].job;
      appName = `${job.company.name} - ${job.seniorityName}`;
      appsListData.push({
        name: appName,
        status: application.applications[i].status,
        appId: application.applications[i].id,
        date: application.applications[i].createdAt,
        declinedReason: application.applications[i].declinedReason,
      });
    }
  }

  const appDetails = [
    {
      application: application,
      id: 1,
      salary: application.salaryToShow,
      availability: application.availability,
      candidateSalary: "0",
      applicationDate: application.createdAt
    },
  ];

  appDetails[0].candidateSalary = String(application.salary);
  if (application.questions && application.questions.length) {
    // eslint-disable-next-line
    for (let i = 0; i < application.questions.length; i++) {
      appDetails[0][`question${i + 1}`] = application.questions[i].question.title;
      appDetails[0][`answer${i + 1}`] = application.questions[i].answer;
    }
  }

  const candidateLinks = [];
  if (githubLink) {
    candidateLinks.push({
      link: githubLink,
      icon: <GitHubIcon />,
      color: "github",
    });
  }
  if (gitLabLink) {
    candidateLinks.push({
      link: gitLabLink,
      icon: <img src={GitlabIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "pinterest",
    });
  }
  if (websiteLink) {
    candidateLinks.push({
      link: websiteLink,
      icon: <img src={WebsiteIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "vimeo",
    });
  }
  if (stackOverflowLink) {
    candidateLinks.push({
      link: stackOverflowLink,
      icon: <img src={stackOverflowIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "dribbble",
    });
  }
  if (portfolioLink) {
    candidateLinks.push({
      link: portfolioLink,
      icon: <img src={PortfolioIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "youtube",
    });
  }
  if (leverLink && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")) {
    candidateLinks.push({
      link: leverLink,
      icon: <SuiAvatar src={leverIcon} alt="Lever icon" size="xs" sx={{ height: 18 }} />,
      color: "twitter",
    });
  }

  const candidateSocials = [];
  if (facebookLink) {
    candidateSocials.push({
      link: facebookLink,
      icon: <FacebookIcon />,
      color: "facebook",
    });
  }
  if (twitterLink) {
    candidateSocials.push({
      link: twitterLink,
      icon: <TwitterIcon />,
      color: "twitter",
    });
  }
  if (instagramLink) {
    candidateSocials.push({
      link: instagramLink,
      icon: <InstagramIcon />,
      color: "instagram",
    });
  }
  if (linkedinLink) {
    candidateSocials.push({
      link: linkedinLink,
      icon: <LinkedInIcon />,
      color: "linkedin",
    });
  }

  const canAcceptApplication = () => {
    /* Admins can edit everything */
    if (user.type === "tecla_admin") return true
    /* Managers can only edit companies they're assigned to */
    if (user.type === "tecla_manager" && recruiter.companies.filter(comp => comp?.id === application?.company?.id).length) return true
    /* No one else can edit anything */
    return false
  }

  const canEditApplication = () => {
    /* Admins can edit everything */
    if (user.type === "tecla_admin" || user.type === "tecla_manager" || user.type === "tecla_recruiter") return true
    /* Managers can only edit companies they're assigned to */
    // if (user.type === "tecla_manager" && recruiter.companies.filter(comp => comp?.id === application?.company?.id).length) return true
    /* No one else can edit anything */
    return false
  }

  let title = "Application Details";
  if (application && application.company && application.job) {
    title = `${title} for ${application.company.name} - ${application.job.seniorityName}`
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const [showApplicationNotesModal, setShowApplicationNotesModal] = useState(false)

  const canEditJobNote = () => {
    /* Admins can edit everything */
    if (user.type === "tecla_admin") return true
    /* Managers can only edit if they have company assigned */
    if (user.type === "tecla_manager") return true
    /* No one else can edit anything */
    if (user.type === "tecla_recruiter") return true
    return false
  }

  const [isFetchingForms, setIsFetchingForms] = useState(false)

  // We use this function to refetch candidate info in RecruiterFormsModal
  // It refreshes candidate information once a new form has been submited
  const reFetchCandidateForms = () => {
    setIsFetchingForms(true)

    const headers = { "Content-Type": "application/json" };
    const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${application?.candidate?.user?.id}/teclaGetCandidate/`;
    headers.Authorization = `Token ${token}`;

    fetch(url, { headers, })
      .then(async response => {
        const data = await response.json();

        if (data.success) {
          const newCandidate = { ...data.data };

          setCandidate(newCandidate);

          ReactSession.set("CandidateForEdit", newCandidate)

          setIsFetchingForms(false)
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  return (
    <RecruiterDashboardLayout>
      <Header 
        candidate={candidate} 
        canShare={canShare} 
        canForms
        reFetchCandidateForms={reFetchCandidateForms}
        isFetchingForms={isFetchingForms}
        canNote 
        appId={appId} 
        token={token} 
        recruiters={recruiters} 
        setRecruiters={setRecruiters}
        notes={notes} 
        updateNotes={updateNotes} 
        setUpdateNotes={setUpdateNotes}
        application={application} 
        canEditApplication={canEditApplication()} 
        canAcceptApplication={canAcceptApplication()} 
        setApplication={setApplication}
        showToClient={showToClient}
        setShowToClient={setShowToClient}
        activities={activities}
      />
      
      {isFetchingApplicationData ?
        <SuiBox display="flex" justifyContent="center" alignItems="center" width="100%" height="70vh">
          <CircularProgress size={40}/>
        </SuiBox>
        :
        <SuiBox display="flex" mt={5} mb={3}>
          <Grid container spacing={3}>
            {/* LEFT */}
            <Grid item xs={12} lg={4}>
              {videoLink ? (
                <Grid item xs={12} mb={3}>
                  <Card>
                    <SuiBox pt={2} px={2}>
                      <SuiBox mb={0.5}>
                        <SuiTypography variant="h6" fontWeight="medium">
                          Intro Video
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={2}>
                        <ReactPlayer url={videoLink} width="100%" />
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Grid>
              ) : ("")}

              <Grid item xs={12}>
                <SuiBox mb={3}>
                  <ProfileSetInfoCard
                    title="profile"
                    description={candidate.bio}
                    info={profileUser}
                    links={candidateLinks}
                    social={candidateSocials}
                    action={{ route: "", tooltip: "Edit Profile" }}
                    candidate={candidate}
                    setCandidate={setCandidate}
                    application={application}
                  />
                </SuiBox>
              </Grid>

              <Grid item xs={12}>
                <SuiBox mb={3}>
                  <SkillsList title="Skills" profiles={skillsetListData} candidateId={candidate?.user?.id} />
                </SuiBox>
              </Grid>

              {englishsetListData.length > 0 && (
                <Grid item xs={12}>
                  <SuiBox mb={3}>
                    <SkillsUpdateList title="English level" candidate={candidate} setCandidate={setCandidate} profiles={englishsetListData} comment={comment} />
                  </SuiBox>
                </Grid>
              )}

              <Grid item xs={12}>
                <SuiBox mb={3}>
                  <ScreeningProcessPanel title="Screening process" application={application} />
                </SuiBox>
              </Grid>

              {application.fromBlast && (
                <Grid item xs={12}>
                  <SuiBox mb={3}>
                    <ApplicationBlasted title="Blasted" />
                  </SuiBox>
                </Grid>
              )}

              {interestListData.length > 0 && (
                <Grid item xs={12}>
                  <SuiBox mb={2}>
                    <InterestList title="Interests" profiles={interestListData} />
                  </SuiBox>
                </Grid>
              )}

            </Grid>

            {/* RIGHT */}
            <Grid item xs={12} lg={8}>
              {/* APPLICATION NOTES */}
              {/* Empty state for recruiter admins and managers that have company assigned */}
              {(!application?.notes || !stripHtml(application?.notes)) && (
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <Card sx={{ p: 2, backgroundColor: '#fff' }}>
                    <SuiTypography variant="h6" fontWeight="medium" mb={1}>Application Notes</SuiTypography>

                    <SuiTypography variant="body2" color="secondary">This application doesn&apos;t have any notes yet.</SuiTypography>
                    <SuiTypography variant="body2" color="secondary">
                      Note: Application notes are only visible to clients. The Avatar and name of the recruiter who creates or edits the note will be shown to the client.
                    </SuiTypography>

                    <SuiBox mx="auto" my={2} display="flex" justifyContent="center" alignItems="center">
                      <SuiButton color="info" variant="gradient" onClick={() => setShowApplicationNotesModal(true)}>Add note</SuiButton>
                    </SuiBox>
                  </Card>
                </Grid>
              )}

              {application?.notes && stripHtml(application?.notes) && (
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <Card sx={{ p: 2, backgroundColor: '#fff' }}>
                    <SuiTypography variant="h6" fontWeight="medium">Application Notes</SuiTypography>

                    <SuiBox sx={{ display: "flex", alignItems: "center", paddingLeft: "20px", my: 1 }}>

                      <SuiBox
                        sx={{
                          mr: 1,
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          backgroundImage: `url(${application?.notesRecruiter?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${application?.notesRecruiter?.user?.photo}` : TeclaLogo})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "50% 50%",
                        }}
                      />

                      <SuiTypography variant="body2" color="secondary" sx={{ width: "130px" }}>
                        {application?.notesRecruiter?.user?.first_name ? `${capitalize(application?.notesRecruiter?.user?.first_name)} ${capitalize(application?.notesRecruiter?.user?.last_name)}` : "TECLA"}
                      </SuiTypography>
                    </SuiBox>

                    <SuiTypography variant="body2" color="secondary">
                      <div dangerouslySetInnerHTML={{ __html: application?.notes }} style={{ paddingTop: "5px", paddingLeft: "20px" }} />
                    </SuiTypography>

                    {canEditJobNote() && (
                      <SuiBox mx="auto" my={2} display="flex" justifyContent="center" alignItems="center">
                        <SuiButton color="info" variant="gradient" onClick={() => setShowApplicationNotesModal(true)}>Edit note</SuiButton>
                      </SuiBox>
                    )}
                  </Card>
                </Grid>
              )}

              {showApplicationNotesModal && (
                <ApplicationNotesModal
                  showApplicationNotesModal={showApplicationNotesModal}
                  setShowApplicationNotesModal={setShowApplicationNotesModal}
                  appId={application?.id}
                  note={application?.notes || ''}
                  fullname={`${candidate?.user?.first_name} ${candidate?.user?.last_name}`}
                />
              )}

              <Card>
                <Grid item xs={15} lg={15}>
                  <Card sx={{ overflowY: "auto", backgroundColor: cv ? '#525758' : '#fff' }}>
                    {cv ?
                      <PdfViewer cvUrl={cv} />
                      :
                      <SuiBox sx={{ minHeight: 400, py: 4 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <SuiTypography variant="h6" fontWeight="medium">The candidate hasn&apos;t uploaded a CV file</SuiTypography>
                        
                        <img src={emptyCVImg} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />
                      </SuiBox>
                    }
                  </Card>

                  {cv && (
                    <SuiBox mx="auto" my={3} display="flex" justifyContent="center" alignItems="center">
                      <SuiButton color="info" variant="gradient" href={cv} target="_blank">Download</SuiButton>
                    </SuiBox>
                  )}
                </Grid>
              </Card>

              {appDetails.length > 0 && <Grid item xs={12} style={{ marginTop: "20px" }}>
                <SuiBox mb={2}>
                  <ApplicationDetails title={title} profiles={appDetails} jobId={application?.job?.id} />
                </SuiBox>
              </Grid>}

              {appsListData.length ? (
                <Grid item xs={12}>
                  <SuiBox mb={2}>
                    <Appications title="Applications" apps={appsListData} />
                  </SuiBox>
                </Grid>
              ) : null}
              
            </Grid>

          </Grid>
        </SuiBox>
      }
    </RecruiterDashboardLayout>
  );
}

export default ApplicationOverviewRecruiter;
