/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// useState,
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// Sesion
import { ReactSession } from 'react-client-session';
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
// import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import ReactGA from "react-ga4";
import routes from "routes";
import notLoggedInRoutes from "notLoggedInRoutes";
import routesCompany from "routesCompany";
import routesLimitedCompany from "routesLimitedCompany";
import routesStudent from "routesStudent";
// Soft UI Dashboard PRO React contexts
// , setOpenConfigurator , setTransparentSidenav
import { useSoftUIController, setMiniSidenav } from "context";
// Custom styles for DashboardNavbar
import {
  // navbar,
  // navbarContainer,
  // navbarRow,
  // navbarIconButton,
  // navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
// import {
//   collapseItem,
//   collapseIconBox,
//   collapseIcon,
//   collapseText,
//   collapseArrow,
// } from "examples/Sidenav/styles/sidenavCollapse";
// Images
import brand from "assets/images/logo-ct.png";
import boostBrand from "assets/images/bosstLogo.png";
import brandName from "assets/images/brand-ct.png";
// import SidenavItem from "examples/Sidenav/SidenavItem";
// import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import CreateNotificationModal from "examples/Modal/Notification";
import CreateAddSkillModal from "examples/Modal/AddSkill";
import CreateAddIndustryModal from "examples/Modal/AddIndustry";
import routesRecruiter from "./routesRecruiter";


export default function App() {
  const [controller, dispatch] = useSoftUIController();
  // , openConfigurator
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  
  // ReactGA.send("pageAppTest");
  ReactSession.setStoreType("localStorage")
  let token = ReactSession.get("token")
  const user = ReactSession.get("user");
  const candidate = ReactSession.get("candidate");
  const student = ReactSession.get("student");
  const company = ReactSession.get("company");
  const recruiter = ReactSession.get("recruiter");
  const currenntUrl = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  const urlRecruiter = searchParams.get('r');
  const utmSource = searchParams.get('utm_source');
  const code = searchParams.get('code');


  // const studentUrls = ["/login", "/join", "/dashboard", "/savedLessons", "/savedLessons", "/quizzes", "/my-coaches", "/events", "/ai-tutor", "/newsletters", "/settings"];
  // if (!student && studentUrls.includes(currenntUrl)) {
  //   window.location.replace("/login");
  // }

  function displayInvoiceLink() {
    setTimeout(() => {
      $("a[href*='invoices']").show();
    }, "1000")
  }

  useEffect(() => {
    if ((candidate && candidate.inTeam) || (company && company.withContracts)) {
      displayInvoiceLink();
    }

    if (utmSource) {
      ReactSession.set("utm", utmSource);
    }
    if (urlRecruiter) {
      ReactSession.set("urlRecruiter", urlRecruiter);
    }
    if (code) {
      ReactSession.set("jobCode", code);
    }

    if (currenntUrl.includes("/t/") && !candidate && !company && !recruiter) {
      ReactGA.initialize(process.env.REACT_APP_DEFAULT_GA_KEY);
      ReactGA.send({ hitType: "pageview", page: currenntUrl });
    } if ((currenntUrl.includes("/t/") || currenntUrl.includes("/job") || currenntUrl.includes("/jobs")) && candidate) {
      ReactGA.initialize(process.env.REACT_APP_CANDIDATE_GA_KEY);
      ReactGA.send({ hitType: "pageview", page: currenntUrl });
    } else if ((currenntUrl.includes("/c/") || currenntUrl.includes("/job") || currenntUrl.includes("/jobs")) && company) {
      ReactGA.initialize(process.env.REACT_APP_COMPANY_GA_KEY);
      ReactGA.send({ hitType: "pageview", page: currenntUrl });
    } else if ((currenntUrl.includes("/r/") || currenntUrl.includes("/job") || currenntUrl.includes("/jobs")) && recruiter) {
      ReactGA.initialize(process.env.REACT_APP_RECRUITER_GA_KEY);
      ReactGA.send({ hitType: "pageview", page: currenntUrl });
    }
  }, []);

  if (!currenntUrl.includes("/t/profile/")) {
    if ((currenntUrl.includes("/login") || currenntUrl.includes("/signup")) && candidate) {
      window.location.replace("/t/dashboard");
    } else if ((currenntUrl.includes("/login") || currenntUrl.includes("/signup")) && company) {
      window.location.replace("/c/dashboard");
    } else if ((currenntUrl.includes("/login") || currenntUrl.includes("/signup")) && recruiter) {
      window.location.replace("/r/dashboard");
    } else if ((currenntUrl.includes("/login") || currenntUrl.includes("/signup")) && student) {
      window.location.replace("/dashboard");
    }
    if (candidate && !candidate.error && (currenntUrl.includes("/c/") || currenntUrl.includes("/r/") || currenntUrl.includes("/e/"))) {
      ReactSession.set("company", null);
      ReactSession.set("recruiter", null);
      ReactSession.set("student", null);
      // ReactSession.set("user", null);
      window.location.replace("/t/dashboard");
    } else if (company && !company.error && (currenntUrl.includes("/t/") || currenntUrl.includes("/r/") || currenntUrl.includes("/e/"))) {
      ReactSession.set("candidate", null);
      ReactSession.set("recruiter", null);
      ReactSession.set("student", null);
      // ReactSession.set("user", null);
      window.location.replace("/c/dashboard");
    } else if (recruiter && !recruiter.error && (currenntUrl.includes("/c/") || currenntUrl.includes("/t/") || currenntUrl.includes("/e/"))) {
      ReactSession.set("company", null);
      ReactSession.set("candidate", null);
      ReactSession.set("student", null);
      // ReactSession.set("user", null);
      window.location.replace("/r/dashboard");
    } else if (student && !student.error && (currenntUrl.includes("/c/") || currenntUrl.includes("/t/")  || currenntUrl.includes("/r/"))) {
      ReactSession.set("company", null);
      ReactSession.set("candidate", null);
      ReactSession.set("recruiter", null);
      // ReactSession.set("user", null);
      window.location.replace("/dashboard");
    } else if (!currenntUrl.includes("/login") && 
      !currenntUrl.includes("/signup") && 
      !currenntUrl.includes("/join") && 
      !currenntUrl.includes("/job") && 
      !currenntUrl.includes("/claim") && 
      !currenntUrl.includes("/m/") && 
      !currenntUrl.includes("/activate") && 
      !currenntUrl.includes("/snooze") && 
      !currenntUrl.includes("/unsubscribe") && 
      !currenntUrl.includes("/reset-password") && 
      !currenntUrl.includes("/forgot-password") && 
      !currenntUrl.includes("/set-password") && 
      !currenntUrl.includes("/new-password") && 
      !currenntUrl.includes("/claimed") && 
      !currenntUrl.includes("/validated") && 
      !currenntUrl.includes("/join") && 
      !currenntUrl.includes("/folder") && 
      !currenntUrl.includes("/recruiterActivate") && 
      !currenntUrl.includes("/profile/") && 
      !user) {
      ReactSession.set("company", null);
      ReactSession.set("recruiter", null);
      ReactSession.set("candidate", null);
      if (window.location.hostname.includes("boostlabs") || student) {
        ReactSession.set("student", null);
        window.location.replace("/login");
      } else {
        ReactSession.set("student", null);
        window.location.replace("/t/login");
      }
    }
  } else {
    const lastStr = currenntUrl.charAt(currenntUrl.length - 1);
    if (!Number.isNaN(Number(lastStr))) {
      token = "";
    }
  }
  
  // const handleWhiteSidenav = () => setTransparentSidenav(dispatch, false);

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = document.body.clientWidth < 1000;
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (!isMobile) {
      if (miniSidenav && !onMouseEnter) {
        setMiniSidenav(dispatch, false);
        setOnMouseEnter(true);
      }
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (!isMobile) {
      if (onMouseEnter) {
        setMiniSidenav(dispatch, true);
        setOnMouseEnter(false);
      }
    }
  };

  
  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
    if (isMobile) {
      setMiniSidenav(dispatch, true);
    }
  }, [direction]);


  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      

      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="1rem"
      zIndex={9999}
      color="info"
      sx={{ cursor: "pointer", top: { xs: "1rem", lg: "initial" }, display: { lg: "none" }, navbarMobileMenu }}
      onClick={() => handleMiniSidenav()}
    >
      <Icon fontSize="default" color="inherit">
        {miniSidenav ? "menu" : "menu_open"}
      </Icon>
    </SuiBox >
  );

  const [openCreateNotificationModal, setOpenCreateNotificationModal] = useState(false)
  const [openCreateSkillModal, setOpenCreateSkillModal] = useState(false)  // eslint-disable-line
  const [openCreateIndustryModal, setOpenCreateIndustryModal] = useState(false) // eslint-disable-line


  const handleNotificationsBtnClick = () => {
    $("ul .notificationsBox span").remove();
    setOpenCreateNotificationModal(true);
  }

  // event listener for notifications sidenav btn
  useEffect(() => {
    
    setTimeout(() => {

      const notificationsBtn = document.getElementById('notificationsMenuBtn')
      // get second parent of notificationsBtn
      const notificationsBtnParent = notificationsBtn?.parentElement?.parentElement
      notificationsBtnParent?.addEventListener("click", () => { handleNotificationsBtnClick() });


      const notificationsBtn2 = document.getElementById('addSkillFromMenu')
      // get second parent of notificationsBtn
      const notificationsBtnParent2 = notificationsBtn2?.parentElement?.parentElement
      notificationsBtnParent2?.addEventListener("click", () => { setOpenCreateSkillModal(true) });


      const notificationsBtn3 = document.getElementById('addIndustryFromMenu')
      // get second parent of notificationsBtn
      const notificationsBtnParent3 = notificationsBtn3?.parentElement?.parentElement
      notificationsBtnParent3?.addEventListener("click", () => { setOpenCreateIndustryModal(true) });
    }, 1000);
      
    return () => {
      const notificationsBtn = document.getElementById('notificationsMenuBtn')
      // get second parent of notificationsBtn
      const notificationsBtnParent = notificationsBtn?.parentElement?.parentElement
      // remove event listener
      notificationsBtnParent?.removeEventListener("click", () => { handleNotificationsBtnClick() });


      const notificationsBtn2 = document.getElementById('addSkillFromMenu')
      // get second parent of notificationsBtn
      const notificationsBtnParent2 = notificationsBtn2?.parentElement?.parentElement
      // remove event listener
      notificationsBtnParent2?.removeEventListener("click", () => { setOpenCreateSkillModal(true) });


      const notificationsBtn3 = document.getElementById('addIndustryFromMenu')
      // get second parent of notificationsBtn
      const notificationsBtnParent3 = notificationsBtn3?.parentElement?.parentElement
      // remove event listener
      notificationsBtnParent3?.removeEventListener("click", () => { setOpenCreateIndustryModal(true) });
    }
  }, [])


  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
    } catch (err) {
      console.error('fetchInterests error', err)
    }
  }

  let interests = sessionStorage.getItem('interests')
  useEffect(() => { 
    if (interests) {
      interests = JSON.parse(sessionStorage.getItem('interests'))
      // check if interests.date is older than 1 day
      if ((interests?.date && new Date() - new Date(interests?.date) > 86400000)) fetchInterests()
    }
    else fetchInterests()
  }, [])

  // eslint-disable-next-line
  return layout === "dashboardCompany" ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboardCompany" && user.type === "limited_recruiter" && user && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName={brandName}
            routes={routesLimitedCompany}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "dashboardCompany" && user.type !== "limited_recruiter" && user && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName={brandName}
            routes={routesCompany}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Switch>
        {getRoutes(routes)}
        {ReactSession.get("user") ? (
          <Redirect from="*" to="/c/dashboard" />
        ) : (
          <Redirect from="*" to="/c/login" />
        )}
      </Switch>
    </ThemeProvider>
  ) : layout === "dashboardRecruiter" ? ( // eslint-disable-line
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboardRecruiter" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName={brandName}
            routes={routesRecruiter}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}

          {openCreateNotificationModal && (
            <CreateNotificationModal
              openCreateNotificationModal={openCreateNotificationModal}
              setOpenCreateNotificationModal={setOpenCreateNotificationModal}
            />
          )}
          {openCreateSkillModal && (
            <CreateAddSkillModal
              openCreateSkillModal={openCreateSkillModal}
              setOpenCreateSkillModal={setOpenCreateSkillModal}
            />
          )}
          {openCreateIndustryModal && (
            <CreateAddIndustryModal
              openCreateIndustryModal={openCreateIndustryModal}
              setOpenCreateIndustryModal={setOpenCreateIndustryModal}
            />
          )}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Switch>
        {getRoutes(routes)}
        {ReactSession.get("user") ? (
          <Redirect from="*" to="/r/dashboard" />
        ) : (
          <Redirect from="*" to="/r/login" />
        )}
      </Switch>
    </ThemeProvider>
  ) : layout === "dashboardStudent" ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {layout === "dashboardStudent" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={student?.studentCompany?.boostStudentsLogo ?
              `${process.env.REACT_APP_API_ROUTE}${student?.studentCompany?.boostStudentsLogo}`
              :
              boostBrand
            }
            brandName='boost'
            routes={routesStudent}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Switch>
        {getRoutes(routes)}
        {ReactSession.get("user") ? (
          <Redirect from="*" to="/dashboard" />
        ) : (
          <Redirect from="*" to="/login" />
        )}
      </Switch>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName={brandName}
            routes={token ? routes : notLoggedInRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Switch>
        {getRoutes(routes)}
        {ReactSession.get("candidate") && !ReactSession.get("candidate").error ? (
          <Redirect from="*" to="/t/dashboard" />
        ) : null}
        {ReactSession.get("company") && !ReactSession.get("company").error ? (
          <Redirect from="*" to="/c/dashboard" />
        ) : null}
        {ReactSession.get("recruiter") && !ReactSession.get("recruiter").error ? (
          <Redirect from="*" to="/r/dashboard" />
        ) : null}
        {(!ReactSession.get("company") || ReactSession.get("company").error) && (!ReactSession.get("candidate") || ReactSession.get("candidate").error)
          && (!ReactSession.get("recruiter") || ReactSession.get("recruiter").error) && (!ReactSession.get("student") || ReactSession.get("student").error) ? (
          <Redirect from="*" to="/t/login" />
        ) : null}
      </Switch>
    </ThemeProvider>
  );
}
