/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// Custom styles for DashboardNavbar
import {
  navbarMobileMenu,
} from "examples/Navbars/JobFilterNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setOpenJobFinder,
} from "context";

function DashboardNavbar({ title, recruiter }) {
  const [controller, dispatch] = useSoftUIController();
  const { openJobFinder } = controller;
  const handleJobFinderOpen = () => setOpenJobFinder(dispatch, !openJobFinder);

  let timerSuccessid = "";
  const [copyDefaultText, setCopyDefaultText] = useState("Share");

  function copyToClipboard(text) {
    const sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text;
    sampleTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);
  }

  function copyTextClick(e) {
    e.preventDefault();
    let copyText = `${process.env.REACT_APP_PROD_ROUTE}jobs`;
    if (recruiter && recruiter.applyTag) {
      copyText = `${copyText}?r=${recruiter.applyTag}`;
    }
    copyToClipboard(copyText);
    setCopyDefaultText("Copied!")
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      setCopyDefaultText("Share")
    }, 2000);
  }

  return (
    <SuiBox display="flex" flexWrap="wrap" width="100%" alignItems="center" justifyContent={{ sx: "start", xl: "space-between" }} my={2}>
      <SuiTypography variant="h3" fontWeight="bold" mr={5}>
        {title}
      </SuiTypography>
      <SuiBox>
        { recruiter && recruiter.user ? (
        <SuiButton
          onClick={copyTextClick}
          size="medium"
          color="info"
          variant="outlined"
          circular
          sx={{ marginRight: "1rem" }}
        >
          {copyDefaultText}
        </SuiButton>
        ) : null }
        { recruiter && recruiter.user && (recruiter.user.type === "tecla_manager" || recruiter.user.type === "tecla_admin") ? (
        <SuiButton
          component="a"
          href="/r/newjob"
          size="medium"
          color="info"
          variant="outlined"
          circular
          sx={{ marginRight: "1rem" }}
        >
          <Icon>
            add
          </Icon>
          &nbsp;New job
        </SuiButton>
        ) : null }
        <SuiButton
          size="medium"
          color="info"
          variant="gradient"
          sx={{
            navbarMobileMenu,
          }}
          onClick={handleJobFinderOpen}
          circular
        >
          <Icon>
            menu
          </Icon>
          &nbsp;Job Filters
        </SuiButton>
      </SuiBox>
    </SuiBox >
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  title: PropTypes.string.isRequired,
  recruiter: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DashboardNavbar;
