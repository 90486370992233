/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import * as React from 'react';
// import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';

import Swal from "sweetalert2";

// import Withdraw from "layouts/pages/profile/applications-list/components/Withdraw";
// import Remove from "layouts/pages/profile/applications-list/components/Remove";

import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";

// import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line

// jobId, status, removeApplication, withdrawApplication, 
function ActionCell({ phase, status, newStatus, lastPhase, moveTo, appId, jobStatus, sourcingAsService }) {
	// const jobLink = `/dashboard/job?id=${jobId}`;

	// const renderMenu = (state, close) => (
	// 	<Menu
	// 		anchorEl={state}
	// 		anchorOrigin={{ vertical: "top", horizontal: "left" }}
	// 		transformOrigin={{ vertical: "top", horizontal: "right" }}
	// 		open={Boolean(state)}
	// 		onClose={close}
	// 		keepMounted
	// 	>
	// 		<MenuItem onClick={close}>Edit</MenuItem>
	// 		<MenuItem onClick={close}>Pause</MenuItem>
	// 		<MenuItem onClick={close}>Cancel Job</MenuItem>
	// 	</Menu>
	// );


	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// (async () => {

	// 	const dialog = await Swal.fire({
	// 		title: 'Multiple inputs',
	// 		html:
	// 			'<select id="swal-input1" class="swal2-input">' +
	// 			'<textarea id="swal-input2" class="swal2-input">',
	// 		focusConfirm: false,
	// 	})

	// 	if (dialog) {
	// 		console.log(dialog)
	// 	}

	// })()
	let selectValue = 0;
	let textAreaValue = "";

	const closedApp = () => Swal.fire({
		title: 'Position closed',
		text: 'This position is closed and no further actions can be taken on the candidates. Please request to re-open this job is you want to manage applicants again.',
		width: "400",
		height: "auto",
		showCloseButton: true,
		allowEscapeKey: "true"
	})

	const introDialog = (oldStatus, newMoveStatus, declinedReason, declinedComment) => Swal.fire({
		title: '',
		text: 'Do you have a calendly link for the meeting? Please paste it here so we can share it with the candidate.',
		width: "400",
		height: "auto",
		input: "text",
		inputPlaceholder: 'Calendly link',
		showCloseButton: true,
		confirmButtonText: 'Confirm',
		confirmButtonAriaLabel: 'Confirm',
		showCancelButton: false,
		allowEscapeKey: "true",
		didOpen: () => {
			$( "<div style='margin: 1em 2em 0;font-size: 16px;line-height: 16px;'>Once you confirm, an introduction to the candidate will be made by TECLA via email as soon as possible. The intro will land in your inbox in the next few hours. Stay tuned!</div>" ).insertAfter( $("input.swal2-input") );
		},
	}).then((result) => {
		if (result.isConfirmed) {
			Swal.fire({
				icon: "success",
				title: "Interview request sent.",
				text: "",
			});
			selectValue = result.value;
			moveTo(appId, phase, oldStatus, newMoveStatus, declinedReason, declinedComment, selectValue);
		}
	})

	const interviewDialog = (oldStatus, newMoveStatus, declinedReason, declinedComment) => Swal.fire({
		title: '',
		text: 'Are you sure to change the state of this application to interviewing?',
		width: "400",
		height: "auto",
		showCloseButton: true,
		confirmButtonText: 'Confirm',
		confirmButtonAriaLabel: 'Confirm',
		showCancelButton: false,
		allowEscapeKey: "true"
	}).then((result) => {
		if (result.isConfirmed) {
			moveTo(appId, phase, oldStatus, newMoveStatus, declinedReason, declinedComment, "");
		}
	})

	const declineDialog = () => Swal.fire({
		icon: "error",
		title: 'Decline Applicant',
		text: 'Select a reason to decline this candidate',
		width: "400",
		height: "auto",
		input: "select",
		inputOptions: {
			0: "Select decline reason",
			1: "Compensation misalignment",
			2: "Employment duration too short",
			3: "English skills not up to par",
			4: "Location",
			5: "Offer declined",
			6: "Overqualified",
			7: "Motivations misalignment",
			8: "Underqualified",
			9: "Unresponsive",
			10: "Withdrew",
			11: "Position closed",
			12: "Better for another role",
		},
		inputPlaceholder: 'Click here to list of reasons',
		showCloseButton: true,
		confirmButtonText: 'Confirm',
		confirmButtonAriaLabel: 'Confirm',
		focusConfirm: true,
		showCancelButton: false,
		allowEscapeKey: "true",
		didOpen: () => {
			$(".swal2-select").val(0);
			$(".swal2-select option").first().remove();
		},
		preConfirm: () => {
	      if (Number($(".swal2-container select").val()) === 0) {
	        Swal.showValidationMessage('Select a valid option')   
	      }
	    }
	}).then((result) => {
		selectValue = Number(result.value) - 1;
		if (result.isConfirmed && selectValue >= 0) {
			Swal.fire({
				icon: 'success',
				title: 'Candidate declined',
				text: 'Add some comments',
				inputPlaceholder: 'Type here...',
				focusConfirm: true,
				width: "400",
				height: "auto",
				input: "textarea",
			}).then((areaResult) => {
				if (areaResult.isConfirmed) {
					textAreaValue = $(".swal2-container textarea").val();
					moveTo(appId, phase, status, "declined", selectValue, textAreaValue, false);
				}
			})
		}
	})

	const declineAction = () => {
		handleClose();
		declineDialog();
	}

	const moveToAction = () =>{
	    handleClose();
	    if (newStatus === "intro") {
	    	introDialog(status, newStatus, 0, "");
	    } else if (newStatus === "interviewing") {
	    	interviewDialog(status, newStatus, 0, "");
	    } else {
	    	moveTo(appId, phase, status, newStatus, 0, "", false);
	    }
	}

	const hireAction = () =>{
	    handleClose();
	    moveTo(appId, phase, status, "hired", 0, "", false);
	}

	const moveToText = phase === "Intro Request" ? "Request Interview" : `Move to ${phase}`

	return (
		<SuiBox display="flex" alignItems="center" >
			{/* <SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={jobLink} target="_blank">
		      <Tooltip title="View job detail" placement="top">
		        <Icon>visibility</Icon>
		      </Tooltip>
		    </SuiTypography> */}
			<SuiTypography
				color="secondary"
				onClick={handleClick}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					mr: "0.75rem",
					alignSelf: "flex-start",
					py: 1.25,
				}}
			>
				{ jobStatus === "closed" && !sourcingAsService ? (
					<SuiButton color="info" variant="outlined" onClick={closedApp}>
						Action
						{/* <Icon fontSize="default" sx={{ cursor: "pointer" }}>
							edit
						</Icon> */}
					</SuiButton>
				) : (
					<SuiButton color="info" variant="outlined">
						Action
						{/* <Icon fontSize="default" sx={{ cursor: "pointer" }}>
							edit
						</Icon> */}
					</SuiButton>
				)}
			</SuiTypography>
			{ jobStatus === "closed" && !sourcingAsService ? null : (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
					keepMounted
				>
					{!lastPhase && status !== "interviewing" ? (
						<MenuItem onClick={moveToAction}>
							{moveToText}
						</MenuItem>
					) : null}

					{phase !== "Prospects" && phase !== "Interviewing" && phase !== "Intro Request" ? (
						<MenuItem onClick={hireAction}>Hire</MenuItem>
					) : null}
					{phase !== "Prospects" ? (
						<MenuItem onClick={declineAction}><SuiTypography variant="body" color="error">Decline</SuiTypography></MenuItem>
					) : null}
				</Menu>
			) }
		</SuiBox>
	);
}

ActionCell.defaultProps = {
	// jobId: 0,
	// status: "",
	// removeApplication: null,
	// withdrawApplication: null,
	phase: "next phase",
	status: "prospects",
	newStatus: "prospects",
	lastPhase: false,
	moveTo: null,
	appId: 0,
	jobStatus: "",
	sourcingAsService: true
}

ActionCell.propTypes = {
	// jobId: PropTypes.number,
	// status: PropTypes.string,
	// removeApplication: PropTypes.func,
	// withdrawApplication: PropTypes.func,
	phase: PropTypes.string,
	status: PropTypes.string,
	newStatus: PropTypes.string,
	lastPhase: PropTypes.bool,
	moveTo: PropTypes.func,
	appId: PropTypes.number,
	jobStatus: PropTypes.string,
	sourcingAsService: PropTypes.bool,
};

export default ActionCell;
