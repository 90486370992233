/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
// @mui material components
import Card from '@mui/material/Card'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// Components

function ApplicationBlasted({ title }) {

  return (
    <Card sx={{ maxHeight: '44vh', overflow: 'auto' }}>
      <SuiBox pt={2} px={2} display='flex' justifyContent='space-between' alignItems='center' maxWidth='97%'>
        <SuiTypography variant='h6' fontWeight='medium' textTransform='capitalize'>
          {title}
        </SuiTypography>
      </SuiBox>

      <SuiBox p={2} maxWidth='95%'>
        <SuiBox component='ul' display='flex' flexDirection='column' p={0} m={0}>
          
          <SuiBox display='flex' justifyContent='space-between' alignItems='center'>
            <SuiBox display='flex' alignItems='center'>
              <SuiTypography variant='body2' fontWeight='light' mr={2}>This candidate applied after being blasted for this position.</SuiTypography>
            </SuiBox>

          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the ApplicationBlasted
ApplicationBlasted.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ApplicationBlasted;
