/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
import ProductCell from "layouts/ecommerce/overview/components/ProductCell";
import { formatDate } from "utils/commonFunctions";
import SourcerCell from "layouts/pages/applicationsRecruiter/components/SourcerCell/sourcerCell";
import EnglishLevel from "layouts/pages/recruiter-applications/components/EnglishLevel";

export default {
  columns: [
    {
      Header: "",
      accessor: "points",
      width: "1%",
      disableSortBy: true,
      Cell: ({ value: points }) => <EnglishLevel points={points} />,
    },
    {
      Header: "Candidate",
      accessor: "product",
      Cell: ({ value: [name, data, url, title] }) => (
        <ProductCell image={data.image} name={name} url={url} title={title} />
      ),
    },
    { Header: "Country", accessor: "country" },
    { Header: "Availability", accessor: "availability" },
    { Header: "Salary", accessor: "salary" },
    { Header: "Decline reason", accessor: "reason" },
    { Header: "Previous status", accessor: "previous" },
    { Header: "Created on", accessor: "date", Cell: ({ value }) => formatDate(value) },
    { Header: "Last change", accessor: "lastStatus" },
    {
      Header: "Sourcer",
      accessor: "sourcer",
      Cell: ({ value: [name, data] }) => <SourcerCell image={data} name={name} />,
    },
    { Header: "Application", accessor: "application" },
    { Header: "Action", accessor: "action" },
  ],

  rows: [],
};
