/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import * as React from 'react';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// MUI
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CheckIcon from '@mui/icons-material/Check';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Tooltip from "@mui/material/Tooltip";

function EnglishLevel({ points }) { // eslint-disable-line

	return (
		<SuiBox display="flex" alignItems="center" >
			<SuiBox>
				{ points && points.englishLevel ? (
					<SuiBox>
						<Tooltip title="English Level 8 or higher" placement="top">
							<UpgradeIcon fontSize="small" sx={{ display: "inline-block", marginTop: "-4px" }}/>
						</Tooltip>
					</SuiBox>
				) : null }
				{ points && points.englishValidate ? (
					<SuiBox>
						<Tooltip title="English Level validated" placement="top">
							<CheckIcon fontSize="small" sx={{ display: "inline-block", marginTop: "-4px" }}/>
						</Tooltip>
					</SuiBox>
				) : null }
				{ points && points.form ? (
					<SuiBox>
						<Tooltip title="This candidate has a Form" placement="top">
							<AssignmentIcon fontSize="small" sx={{ display: "inline-block", marginTop: "-4px" }}/>
						</Tooltip>
					</SuiBox>
				) : null }
			</SuiBox>
		</SuiBox>
	);
}

EnglishLevel.defaultProps = {
	points: null,
}

EnglishLevel.propTypes = {
	points: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default EnglishLevel;
