/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken, formatDate } from "utils/commonFunctions"; // eslint-disable-line
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress'
import Badge from '@mui/material/Badge';
import useMediaQuery from "@mui/material/useMediaQuery"
import Swal from "sweetalert2";
// Soft UI Dashboard PRO React components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Checkbox from "@mui/material/Checkbox";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
// Soft UI Dashboard PRO React example components
import ApplicationState from "layouts/pages/recruiter-applications/components/ApplicationState";
import Interviewed from "layouts/pages/recruiter-applications/data/interviewedTableData";
import ActionCell from "layouts/pages/recruiter-applications/components/ActionCell";
import Review from "layouts/pages/recruiter-applications/data/reviewTableData";
import Prospects from "layouts/pages/recruiter-applications/data/dataTableData";
import Intro from "layouts/pages/recruiter-applications/data/introTableData";
import Interviewing from "layouts/pages/recruiter-applications/data/interviewingTableData";
import Declined from "layouts/pages/recruiter-applications/data/declinedTableData";
import Hired from "layouts/pages/recruiter-applications/data/hiredTableData";
import Withdrew from "layouts/pages/recruiter-applications/data/withdrewTableData";
// Assets
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";


function Cameras() {
  ReactSession.setStoreType("localStorage");
  const sesionUser = ReactSession.get("user");
  const token = ReactSession.get("token");
  const recruiter = ReactSession.get("recruiter");

  const searchParams = new URLSearchParams(window.location.search);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [firstTimeFetch, setFirstTimeFetch] = useState(true);

  const [selectedApps, setSelectedApps] = useState([]);
  const [camera, setCamera] = useState(0);
  const applicationStatesTables = {
    "review": Review,
    "interviewed": Interviewed,
    "prospects": Prospects,
    "intro": Intro,
    "interviewing": Interviewing,
    "declined": Declined,
    "hired": Hired,
    "withdrew": Withdrew,
  }
  const [applicationStates, setApplicationState] = useState(applicationStatesTables);
  const [jobName, setJobName] = useState("");
  const [company, setCompany] = useState({});
  
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isMovingData, setIsMovingData] = useState(true);
  const [updatedApp, setUpdatedApp] = useState(0);

  const formatAvailability = availability => {
    switch (availability) {
      case '1-week':
        return '1 week'
      case '2-weeks':
        return '2 weeks'
      case '3-weeks':
        return '3 weeks'
      case '4-weeks':
        return '4 weeks'  
      default:
        return availability
    }
  }

  const formatDeclineReason = code => {
    switch (code) {
      case "0":
        return "Compensation misalignment"
      case "1":
        return "Employment duration too short"
      case "2":
        return "English skills not up to par"
      case "3":
        return "Location"
      case "4":
        return "Offer declined"
      case "5":
        return "Overqualified"
      case "6":
        return "Motivations misalignment"
      case "7":
        return "Underqualified"
      case "8":
        return "Unresponsive"
      case "9":
        return "Withdrew"
      case "10":
        return "Position closed"
      case "11":
        return "Better for another role"
      case "12":
        return "Culture Fit"
      case "13":
        return "Keep for future opportunities"
      case "14":
        return "Timing"
      default:
        return ""
    }
  }

  function moveTo(appId, phase, oldStatus, newMoveStatus, declinedReason, declinedComment) {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/setStatus/`;
    let apiStatus = "active";
    // eslint-disable-next-line
    if (newMoveStatus === "intro") {
      apiStatus = "intro-requested";
    } else if (newMoveStatus === "interviewed") {
      apiStatus = "interviewed";
    } else if (newMoveStatus === "interviewing") {
      apiStatus = "interview";
    } else if (newMoveStatus === "declined") {
      apiStatus = "declined";
    } else if (newMoveStatus === "hired") {
      apiStatus = "hired";
    } else if (newMoveStatus === "withdrew") {
      apiStatus = "withdrew";
    } else if (newMoveStatus === "review") {
      apiStatus = "in-review";
    }
    const postBody = {
      "status": apiStatus,
      "declinedReason": String(declinedReason),
      "declinedComment": declinedComment
    };

    const postToken = `Token ${token}`;

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': postToken
      },
      body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          setUpdatedApp(updatedApp + 1);
        }

      });
  }

  const declineSelected = () => Swal.fire({
    icon: "error",
    title: 'Decline Selected Applicants',
    text: 'Select a reason to decline the chosen candidates. An email will be sent to them with the selected decline reason.',
    width: "400",
    height: "auto",
    input: "select",
    inputOptions: {
      0: "Select decline reason",
      1: "Compensation misalignment",
      2: "Employment duration too short",
      3: "English skills not up to par",
      4: "Location",
      6: "Overqualified",
      7: "Motivations misalignment",
      8: "Underqualified",
      9: "Unresponsive",
      10: "Withdrew",
      11: "Position closed",
      12: "Better for another role",
      13: "Culture Fit",
      14: "Timing",
    },
    inputPlaceholder: 'Click here to list of reasons',
    showCloseButton: true,
    confirmButtonText: 'Confirm',
    confirmButtonAriaLabel: 'Confirm',
    focusConfirm: true,
    showCancelButton: false,
    allowEscapeKey: "true",
    didOpen: () => {
      $(".swal2-select").val(0);
      $(".swal2-select option").first().remove();
    },
    preConfirm: () => {
      if (Number($(".swal2-container select").val()) === 0) {
        Swal.showValidationMessage('Select a valid option')   
      }
    }
  }).then((result) => {
    const selectValue = Number(result.value) - 1;
    if (result.isConfirmed && selectValue >= 0) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/declineApplications/`;
      const postBody = {
        "ids": [ ...selectedApps ],
        "declinedReason": selectValue
      };
      const postToken = `Token ${token}`;
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(postBody)
      };
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            setSelectedApps([])
            setUpdatedApp(updatedApp + 1);
          }

        });
    }
  })

  const setApplicationChecked = (appId) => {
    setSelectedApps((prevSelectedApps) => {
      let tempSelectedApps = [...prevSelectedApps];
      if (prevSelectedApps.includes(appId)) {
        tempSelectedApps = tempSelectedApps.filter(value => value !== appId);
      } else {
        tempSelectedApps.push(appId);
      }
      return tempSelectedApps;
    });
  };

  function buildRow(applications, freelanceSalaries, apiCompany, requested, isApplication) {

    const rows = [];
    let row = {};
    let user = {};
    let image = mockCandidateAvatar;
    let monthlySalary = "";
    let annualSalary = "";
    let salaryTooltip = "Salary shown to the client"
    if (requested) salaryTooltip = "Salary requested by the candidate"

    // eslint-disable-next-line
    for (const application of applications) {
      if (application.user) {
        let actionNext = "Intro Request";
        // eslint-disable-next-line
        let status = application.status;
        let newStatus = "prospects";
        if (application.status === "active") {
          status = "prospects";
          newStatus = "intro";
        }
        if (application.status === "Interviewed") {
          status = "interviewed";
          newStatus = "intro";
        }
        if (application.status === "intro-requested") {
          actionNext = "Interviewing";
          status = "intro";
          newStatus = "interviewing";
        }
        if (application.status === "interview") {
          actionNext = "Interviewing";
          status = "interviewing";
          newStatus = "declined";
        }
        if (application.status === "declined") {
          actionNext = "Interviewing";
        }
        if (application.status === "hired") {
          actionNext = "Interviewing";
        }
        actionNext = application.status === "intro-requested" ? "Interviewing" : actionNext;
        actionNext = application.status === "interview" ? "Declined" : actionNext;
        actionNext = application.status === "declined" ? "Prospects" : actionNext;
        actionNext = application.status === "hired" ? "Prospects" : actionNext;
        user = application.user;
        image = { image: mockCandidateAvatar };
        if (user && user.photo) {
          image = { image: `${process.env.REACT_APP_API_ROUTE}${user.photo}` };
        }
        let country = "";
        if (user && user.candidate && user.candidate.country) {
          country = user.candidate.country.name;
        }

        const action = <ActionCell token={token} validated={application.validated} phase={actionNext} status={status} newStatus={newStatus} moveTo={moveTo} appId={application.id} hiringMethod={application.job.hiringMethod} company={apiCompany} monthlySalary={application.monthly_salary} salary={application.salary} updatedApp={updatedApp} setUpdatedApp={setUpdatedApp} />;
        let appLink = <Link to={`/r/application/${application.id}`} target="_blank" style={{ color: "#67748e" }}><Tooltip title="View Application" placement="top"><SendIcon style={{ width: "30px", height: "30px" }} /></Tooltip></Link>;
        if (isApplication) {
          appLink = <SuiBox>{appLink}<Tooltip title="Select candidate to be declined" placement="top"><Checkbox sx={{ verticalAlign: "top", marginTop: "5px", marginLeft: "5px" }} onChange={() => setApplicationChecked(application.id)}/></Tooltip></SuiBox>
        }

        /* SALARY */
        const isFreelanceJob =  application?.job?.commitment?.name === "Freelance"
        let salary = ''

        if (isFreelanceJob) {
          salary = <div>Hourly Rate: ${freelanceSalaries?.min} - ${freelanceSalaries?.max}</div>
        }
        else {
          monthlySalary = application?.monthly_salary?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line
          annualSalary = application?.annual_salary?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line
          salary = <div>${monthlySalary} /Month<br/>${annualSalary} /Year</div>
        }

        let reasonText = "";
        if (application.status === "declined") {
          reasonText = formatDeclineReason(application.declinedReason)
        } else if (application.status === "withdrew") {
          reasonText = "Withdraw comments";
        }

        let reasonComments = "";
        if (application.status === "declined" && application.declinedComment) {
          reasonComments = `Decline comments: ${application.declinedComment}`;
        } else if (application.status === "declined" && !application.declinedComment) {
          reasonComments = "Decline comments: No comments";
        } else if (application.status === "withdrew" && application.withdrawReason) {
          reasonComments = `Withdraw comments: ${application.withdrawReason}`;
        } else if (application.status === "withdrew" && !application.withdrawReason) {
          reasonComments = "Withdraw comments: No comments";
        }
        
        const reason = <Tooltip title={reasonComments} placement="top"><div>{reasonText}</div></Tooltip>;
        
        row = {
          points: application.verifiedPoints,
          product: [`${user.first_name} ${user.last_name}`, image, `/r/application/${application.id}`, user.candidate.title ],
          country: country,
          availability: formatAvailability(application.availability),
          salary: <Tooltip title={salaryTooltip} placement="top">{salary}</Tooltip>,
          reason: reason,
          previous: application.previousStatus,
          action,
          date: application.createdAt,
          lastStatus: application.lastStatusChangeDate ? formatDate(application.lastStatusChangeDate) : "N/A",
          application: appLink,
          app: application,
          job: application.job,
          sourcer: [`${application?.recruiter?.user?.first_name} ${application?.recruiter?.user?.last_name}`, application?.recruiter?.user?.photo],
        }

        rows.push(row);
      }
    }

    return rows;
  }

  useEffect(() => {
    const appId = searchParams.get('id');
    if (appId) {
      setIsMovingData(true);
      const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${appId}/companyJobApplications/`;
      const headers = { "Content-Type": "application/json" };
      headers.Authorization = `Token ${token}`;
      fetch(url, { headers, })
        .then(async response => {
          const data = await response.json();

          const freelanceSalaries = {min: data?.data?.minSalary, max: data?.data?.maxSalary}

          // eslint-disable-next-line
          const applications = data.data.applications;
          const tempApplicationStates = { ...applicationStates };
          if (firstTimeFetch) {
            tempApplicationStates.review.columns = tempApplicationStates.review.columns.slice(0, -1);
            tempApplicationStates.interviewed.columns = tempApplicationStates.interviewed?.columns?.slice(0, -1);
            if (sesionUser.type !== "tecla_admin" && !recruiter.companies.filter(comp => Number(comp.id) === Number(data.data.company.id)).length) {
              tempApplicationStates.prospects.columns = tempApplicationStates.prospects?.columns?.slice(0, -1);
              tempApplicationStates.intro.columns = tempApplicationStates.intro?.columns?.slice(0, -1);
              tempApplicationStates.interviewing.columns = tempApplicationStates.interviewing?.columns?.slice(0, -1);
              tempApplicationStates.declined.columns = tempApplicationStates.declined?.columns?.slice(0, -1);
              tempApplicationStates.hired.columns = tempApplicationStates.hired?.columns?.slice(0, -1);
              tempApplicationStates.withdrew.columns = tempApplicationStates.withdrew?.columns?.slice(0, -1);
            }
          }

          tempApplicationStates.interviewed.rows = buildRow(applications?.interviewed, freelanceSalaries, data.data.company, true, false);
          tempApplicationStates.review.rows = buildRow(applications['in-review'], freelanceSalaries, data.data.company, true, true);
          tempApplicationStates.prospects.rows = buildRow(applications?.active, freelanceSalaries, data.data.company, false, false);
          tempApplicationStates.intro.rows = buildRow(applications['intro-requested'], freelanceSalaries, data.data.company, false, false);
          tempApplicationStates.interviewing.rows = buildRow(applications?.interview, freelanceSalaries, data.data.company, false, false);
          tempApplicationStates.declined.rows = buildRow(applications?.declined, freelanceSalaries, data.data.company, true, false);
          tempApplicationStates.hired.rows = buildRow(applications?.hired, freelanceSalaries, data.data.company, false, false);
          tempApplicationStates.withdrew.rows = buildRow(applications?.withdrew, freelanceSalaries, data.data.company, true, false);
          setApplicationState(tempApplicationStates);

          setJobName(data.data.seniorityName);
          setCompany(data.data.company);
          setIsLoadingData(false)
          setIsMovingData(false)
          setFirstTimeFetch(false)
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }

  }, [updatedApp]);

  useEffect(() => {
    setSelectedApps([])
  }, [camera]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);

  }, [tabsOrientation]);

  const handleSetCamera = (event, newCamera) => setCamera(newCamera)

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <>
      {isLoadingData ?
        <SuiBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress size={40} />
        </SuiBox >
        :
        <>
          <SuiBox mb={2} p={2}>
            <SuiTypography variant="h3" fontWeight="bold" component="a" target="_blank" href={`/job?id=${searchParams.get('id')}`} sx={{ display: "block" }}>
              {jobName}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text" component="a" target="_blank" href={`/r/company/${company?.id}`}>
              {company.name}
            </SuiTypography>
          </SuiBox>

          <Card sx={{ minHeight: "65vh" }}>
            {isMovingData ? (
              <SuiBox className="moonLoaderBox active" py={20} display="flex" justifyContent="center" alignItems="center" >
                <CircularProgress />
              </SuiBox>
            ) : null }
            <SuiBox pt={3} px={3}>
              <SuiBox display="flex" justifyContent="start" alignItems="center" width="100%">
                <SuiBox width="100%">
                  <AppBar position="static">
                    <Tabs orientation={tabsOrientation} value={camera} onChange={handleSetCamera}>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.review?.rows?.length}
                          max={999}
                          onClick={() => setCamera(0)}
                        >
                          <Tab label="Applicants" />
                        </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.interviewed?.rows?.length}
                          max={999}
                          onClick={() => setCamera(1)}
                        >
                          <Tab label="Screened" />
                        </Badge>
                      </SuiBox>
                      
                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.prospects?.rows?.length}
                          max={999}
                          onClick={() => setCamera(2)}
                        >
                          <Tab label="Submitted" />
                        </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.intro?.rows?.length}
                          max={999}
                          onClick={() => setCamera(3)}
                        >
                          <Tab label="Intro Requested" />
                        </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.interviewing?.rows?.length}
                          max={999}
                          onClick={() => setCamera(4)}
                        >
                          <Tab label="Interviewing" />
                        </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.declined?.rows?.length}
                          max={999}
                          onClick={() => setCamera(5)}
                        >
                          <Tab label="Declined" />
                        </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.hired?.rows?.length}
                          max={999}
                          onClick={() => setCamera(6)}
                        >
                          <Tab label="Hired" />
                        </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.withdrew?.rows?.length}
                          max={999}
                          onClick={() => setCamera(7)}
                        >
                          <Tab label="Withdrew" />
                        </Badge>
                      </SuiBox>

                    </Tabs>
                  </AppBar>
                </SuiBox>
              </SuiBox>
            </SuiBox>
            <SuiBox p={2} mt={1} width="100%" height="100%">
              <SuiBox key={updatedApp}>
                <ApplicationState title="Applicants" value={camera} index={0} table={applicationStates.review} />
              </SuiBox>
              <ApplicationState title="Screened" value={camera} index={1} table={applicationStates.interviewed} />
              <ApplicationState title="Submitted" value={camera} index={2} table={applicationStates.prospects} />
              <ApplicationState title="Intro Requested" value={camera} index={3} table={applicationStates.intro} />
              <ApplicationState title="Interviewing" value={camera} index={4} table={applicationStates.interviewing} />
              <ApplicationState title="Declined" value={camera} index={5} table={applicationStates.declined} />
              <ApplicationState title="Hired" value={camera} index={6} table={applicationStates.hired} />
              <ApplicationState title="Withdrew" value={camera} index={7} table={applicationStates.withdrew} />
              { selectedApps.length > 0 ? (
                <SuiButton size="medium" color="info" variant="outlined" circular sx={{ marginRight: "2rem", marginTop: "-15px", float: "right" }}  onClick={() => declineSelected()}>Decline Selected</SuiButton>
              ) : null }
            </SuiBox>
          </Card >
        </>
      }
    </>
  );
}

export default Cameras;
