/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import * as React from 'react';
import PropTypes from "prop-types"
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
import CircularProgress from '@mui/material/CircularProgress';
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid"
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from "@mui/material/useMediaQuery"
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { styled } from '@mui/material/styles';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiInput from "components/SuiInput"
import SuiSelect from "components/SuiSelect"
import SuiButton from "components/SuiButton"
import SuiTagInput from "components/SuiTagInput"
import SuiEditor from "components/SuiEditor";


function valuetext(value) {
  return `$${value}`;
}

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
      <Tooltip enterTouchDelay={0} placement="top" title={value} open="true">
        {children}
      </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomSlider = styled(Slider)({
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: 'transparent !important',
    color: 'transparent !important',
  },
  '& .css-1cjy8su-MuiSlider-track': {
    'background-image': 'none',
  },  
});

function OnePage({
                  formData,
                  firstView,
                  tagOptions,
                  selectedTagsNames,
                  setSelectedTagsNames,
                  selectedRoleBenefitsIds,
                  setSelectedRoleBenefitsIds,
                  setIsSalaryBasedOnExperience,
                  setSelectedRoleCommitment,
                  commitmentOptions,
                  isSalaryBasedOnExperience,
                  skillLevels,
                  setSkillLevels,
                  fullCountriesList,
                  selectedRoleCommitment,
                  showMaxSalary,
                  setShowMaxSalary,
                  fixedSalary,
                  setFixedSalary,
                }) {

  const { formField, values, errors, touched } = formData;
  const { bio } = formField;
  const { bio: bioV } = values;

  // Basic Start
  const method = [
    { value: 'dh', label: "Direct Hire (contractor)" },
    { value: 'dhp', label: "Direct Hire (payroll)" },
    { value: 'sa', label: "Staff Augmentation" },
  ]  
  const jobtype = [
    { value: 'r', label: "Remote" },
    { value: 'rr', label: "Remote + Relocation" },
    { value: 'ir', label: "Immediate Relocation" },
    { value: 'os', label: "On-site" },
    { value: 'hy', label: "Hybrid" }
  ]
  const seniority = [
    { value: 'jr', label: "Junior (1-2 years of experience)" },
    { value: 'ml', label: "Mid Level (3-5 years of experience)" },
    { value: 'sr', label: "Senior (6+ years of experience)" },
  ]
  const category = [
    { value: 'eng', label: "Engineering" },
    { value: 'qa', label: "QA" },
    { value: 'dg', label: "Design" },
    { value: 'do', label: "DevOps" },
    { value: 'da', label: "Data" },
    { value: 'pro', label: "Product" },
    { value: 'mg', label: "Management" },
    { value: 'mk', label: "Marketing" },
    { value: 'sl', label: "Sales" },
    { value: 'ta', label: "Talent Acquisition" },
    { value: 'op', label: "Operations" },
  ]
  const englishRequired = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ]

  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token")
  const user = ReactSession.get("user")

  let lastApplySesion = ReactSession.get("lastApply");

  // States
  const [lastApply, setLastApply] = useState({});
  const [companies, setCompanies] = useState([]);
  const [questionsCount, setQuestionsCount] = useState(0) // eslint-disable-line
  const [skills, setSkills] = useState([]);
  const [experiencieSkillLevels, setExperiencieSkillLevels] = useState([]);
  const [optionalSkillLevels, setOptionalSkillLevels] = useState([]);
  const [salaryValue, setSalaryValue] = React.useState([5000, 10000])
  const [noMaxSalaryValue, setNoMaxSalaryValue] = React.useState(30)
  const [companyDescription, setCompanyDescription] = useState("");
  const [description, setDescription] = useState(""); // eslint-disable-line
  const [countries, setCountries] = useState([]);
  const [englishRequiredSelected, setEnglishRequiredSelected] = useState("");

  const searchParams = new URLSearchParams(window.location.search);
  const jobId = searchParams.get('id');

  let defaultLoading = false;
  if (jobId) {
    defaultLoading = true;
  }
  const [loading, setLoading] = useState(defaultLoading); // eslint-disable-line

  /* BENEFITS PICKER */
  const [benefitsOpts, setBenefitsOpts] = useState([])
  const [selectedRoleBenefitsNames, setSelectedRoleBenefitsNames] = useState([])
  const hasHourlyRate = selectedRoleCommitment?.id === 3

  function loadCountries(setTags, userCountryTags) {

    const newData = [];
    newData.push({ value: "0", label: "Anywhere in LATAM" });
    const countryTags = [];
    const initCountries = [];

    // eslint-disable-next-line
    Object.keys(fullCountriesList).forEach(function (key) {
      if (fullCountriesList[key] && !fullCountriesList[key].excludeInJobs) {
        
        // Don't add United States after removing "Everywher in latam"
        if (fullCountriesList[key].name !== 'United States') {
            newData.push({ value: fullCountriesList[key].name, label: fullCountriesList[key].name });
            countryTags.push(fullCountriesList[key].name);
            initCountries.push(fullCountriesList[key]);
        }
      }
    });

    // If tag input contains 'United states' and it isn't being removed, keep it
    if (skillLevels.includes('United States')) {
      countryTags.push('United States')
      newData.push({value: 'United States', label: 'United States'})
      initCountries.push({
        name: "United States",
        code: "US",
        id: 29,
        excludeInJobs: false
      })
    }

    $(".skillsBox:first").attr("data-countries", JSON.stringify(initCountries));
    if (!userCountryTags) {
      setCountries(newData);
      if (setTags) {
        setSkillLevels(countryTags);
      }
    } else {
      setCountries(newData);
      if (userCountryTags.length < 26) {
        setSkillLevels(userCountryTags);
      }
    }
  }

  useEffect(() => {
    lastApplySesion = ReactSession.get("lastApply");

    if (firstView && jobId && token) {
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      headers.Authorization = `Token ${token}`;
      const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/jobDetail/?jobId=${jobId}`;
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
          validateToken(res) // eslint-disable-line
          if (res.success) {
            lastApplySesion = {};
            // eslint-disable-next-line
            const job = res.data.job;
            lastApplySesion.name = job.name;
            lastApplySesion.hiringMethod = job.hiringMethod;
            lastApplySesion.location = job.location;
            lastApplySesion.seniority = job.seniority;
            lastApplySesion.category = job.category;

            lastApplySesion.description = job.description;
            lastApplySesion.companyDescription = job.companyDescription;

            let descriptionDefault = "";
            if (lastApplySesion.companyDescription) descriptionDefault = lastApplySesion.companyDescription

            let defaultValue = "";
            if (lastApplySesion.description) defaultValue = lastApplySesion.description;

            setDescription(defaultValue)
            setCompanyDescription(descriptionDefault)

            lastApplySesion.requiredSkills = [];
            lastApplySesion.optionalSkills = [];

            if (job?.salaryBasedOnExperience) {
              setIsSalaryBasedOnExperience(true)
            } else if (job?.fixedSalary) {
              setFixedSalary(true)
            }
            // eslint-disable-next-line
            for (const requiredSkill of job.requiredSkills) {
              lastApplySesion.requiredSkills.push({ "level": requiredSkill.level, "skill": { "id": requiredSkill.skill.id, "name": requiredSkill.skill.name } });
            }
            // eslint-disable-next-line
            for (const optionalSkill of job.optionalSkills) {
              lastApplySesion.optionalSkills.push({ "level": optionalSkill.level, "skill": { "id": optionalSkill.skill.id, "name": optionalSkill.skill.name } });
            }

            lastApplySesion.questions = [];
            lastApplySesion.jobQuestions = [];
            // eslint-disable-next-line
            for (const question of job.questions) {
              if (!question.isJobQuestion) {
                lastApplySesion.questions.push({ "title": question.title });
              } else {
                lastApplySesion.jobQuestions.push({ "title": question.title });
              }
            }
            setQuestionsCount(lastApplySesion.questions.length)

            lastApplySesion.countries = [];
            // eslint-disable-next-line
            for (const country of job.countries) {
              lastApplySesion.countries.push({ "name": country.name, "id": country.id });
            }
            lastApplySesion.minSalary = job.minSalary;
            lastApplySesion.maxSalary = job.maxSalary;
            lastApplySesion.companyName = job.company.name;
            lastApplySesion.companyId = job.company.id;
            lastApplySesion.applicationsCount = job.applicationsCount;
            lastApplySesion.commitment = job?.commitment

            /* UPDATE SELECTED TAGS */
            const tagsArr = []
            job?.tags?.forEach(item => tagsArr?.push(item?.name))
            if (tagsArr.length > 0) {
              lastApplySesion.tags = tagsArr
              setSelectedTagsNames(tagsArr)
            }

            /* UPDATE SELECTED BENEFITS NAMES */
            const benefitsNamesArr = []
            job?.benefits?.forEach(item => benefitsNamesArr?.push(item?.name))
            if (benefitsNamesArr.length > 0) {
              setSelectedRoleBenefitsNames(benefitsNamesArr)
            }

            /* UPDATE SELECTED BENEFITS IDS */
            const benefitsIdsArr = []
            job?.benefits?.forEach(item => benefitsIdsArr?.push({id: parseInt(item?.id, 10)}))
            if (benefitsIdsArr.length > 0) {
              lastApplySesion.benefits = benefitsIdsArr
              setSelectedRoleBenefitsIds(benefitsIdsArr)
            }

            const defaultRSkills = [];
            const defaultOSkills = [];
            if (lastApplySesion.requiredSkills) {
              // eslint-disable-next-line
              for (const requiredSkill of lastApplySesion.requiredSkills) {
                defaultRSkills.push(`${requiredSkill.skill.name} - ${requiredSkill.level}`);
              }
            }
            if (lastApplySesion.optionalSkills) {
              // eslint-disable-next-line
              for (const optionalSkill of lastApplySesion.optionalSkills) {
                defaultOSkills.push(`${optionalSkill.skill.name} - ${optionalSkill.level}`);
              }
            }
            if (defaultRSkills) {
              setExperiencieSkillLevels(defaultRSkills)
            }
            if (defaultOSkills) {
              setOptionalSkillLevels(defaultOSkills)
            }

            let defaultSalaryValue = [5000, 10000]
            if (lastApplySesion.minSalary && lastApplySesion.maxSalary) defaultSalaryValue = [Number(lastApplySesion.minSalary), Number(lastApplySesion.maxSalary)]
            if (hasHourlyRate) defaultSalaryValue = [30, 50]
            setSalaryValue(defaultSalaryValue)

            let noMaxSalaryDefaultValue = 5000
            if (lastApplySesion.minSalary) noMaxSalaryDefaultValue = Number(lastApplySesion.minSalary)
            if (hasHourlyRate) noMaxSalaryDefaultValue = 30
            setNoMaxSalaryValue(noMaxSalaryDefaultValue)

            ReactSession.set("lastApply", lastApplySesion);
            setLastApply(lastApplySesion);
            setEnglishRequiredSelected(job.englishRequired)
            setLoading(false);


            if (job.countries && job.countries.length) {
              const countryTags = [];
              // eslint-disable-next-line
              for (const countryIndex in job.countries) {
                countryTags.push(job.countries[countryIndex].name);
              }

              // setSkillLevels(countryTags);
              loadCountries(false, countryTags);
            }

            if (job.minSalary && job.maxSalary) {
              $(".salaryRange").attr("data-min", job.minSalary).attr("data-max", job.maxSalary);
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    } else if (!jobId && firstView) {
      ReactSession.set("lastJobId", 0);
      ReactSession.set("lastApply", {});
      setLastApply({});
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      headers.Authorization = `Token ${token}`;
      const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/teclaJobQuestions/`;
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
          if (res.success) {
            const tempLastApply = {};
            tempLastApply.jobQuestions = [];
            // eslint-disable-next-line
            for (const tempQuestion of res.data) {
              tempLastApply.jobQuestions.push({ "title": tempQuestion.title });
            }
            setLastApply(tempLastApply);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    } else {
      lastApplySesion = ReactSession.get("lastApply");
      setLastApply(lastApplySesion);
    }

    const headers = { "Content-Type": "application/json" };
    if (token) headers.Authorization = `Token ${token}`;

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterCompanies/`, { headers })
      .then(async response => {
        const data = await response.json();

        const newData = [];
        // eslint-disable-next-line
        for (let i = 0; i < data.data.length; i++) {
          newData.push({ value: data.data[i].id, label: data.data[i].name });
        }

        setCompanies(newData);
        // this.setState({ totalReactPackages: data.total })
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [])

  useEffect(() => {
    if (selectedRoleCommitment) {
      const tempHasHourlyRate = selectedRoleCommitment?.id === 3

      let defaultSalaryValue = [5000, 10000]
      if (lastApplySesion.minSalary && lastApplySesion.maxSalary) defaultSalaryValue = [Number(lastApplySesion.minSalary), Number(lastApplySesion.maxSalary)]
      if (tempHasHourlyRate) defaultSalaryValue = [30, 50]
      setSalaryValue(defaultSalaryValue)

      let noMaxSalaryDefaultValue = 5000
      if (lastApplySesion.minSalary) noMaxSalaryDefaultValue = Number(lastApplySesion.minSalary)
      if (tempHasHourlyRate) noMaxSalaryDefaultValue = 30
      setNoMaxSalaryValue(noMaxSalaryDefaultValue)
    }
  }, [selectedRoleCommitment])

  /* OPTIONAL TAGS PICKER */
  const addOptionalTag = () => {
    const selectedTagId = $(".optionalBox input[name=tag]").val()
    const selectedTagName = tagOptions?.filter(opt => opt?.value === parseInt(selectedTagId, 10))[0]?.label

    if (selectedTagName && !selectedTagsNames.includes(selectedTagName)) {
      const newNamesArr = [...selectedTagsNames]
      newNamesArr.push(selectedTagName)
      setSelectedTagsNames(newNamesArr)
    }
  }

  const updateTags = newTags => setSelectedTagsNames(newTags)

  const canAddTags = () => {
    /* Onle admins and managers can */
    if (user.type === "tecla_admin" || user.type === "tecla_manager") return true
    return false
  }

  /* BENEFITS PICKER */
  const addBenefit = () => {
    const selectedBenefitId = $(".benefitsBox input[name=benefit]").val()

    /* UPDATE SELECTED BENEFITS IDS */
    const benefitIdWasPreviouslySelected = selectedRoleBenefitsIds?.filter(item => item?.id === parseInt(selectedBenefitId, 10))?.length > 0

    if (selectedBenefitId && !benefitIdWasPreviouslySelected) {
      const newBenefitsArr = [...selectedRoleBenefitsIds]
      newBenefitsArr.push({id: parseInt(selectedBenefitId, 10)})
      setSelectedRoleBenefitsIds(newBenefitsArr)
    }
    
    /* UPDATE NAMES BOX */
    const selectedBenefitName = benefitsOpts?.filter(opt => opt?.value === parseInt(selectedBenefitId, 10))[0]?.label
    
    if (selectedBenefitName && !selectedRoleBenefitsNames.includes(selectedBenefitName)) {
      const newNamesArr = [...selectedRoleBenefitsNames]
      newNamesArr.push(selectedBenefitName)
      setSelectedRoleBenefitsNames(newNamesArr)
    }

    $(".benefitsBox .react-tag-input").removeClass("error")
  }

  const updateBenefits = newBenefits => {
    /* UPDATE SELECTED BENEFITS IDS */
    const newBenefitsArr = []
    newBenefits?.forEach(benefit => {
      const benefitId = benefitsOpts?.filter(opt => opt?.label === benefit)[0]?.value
      newBenefitsArr.push({id: parseInt(benefitId, 10)})
    })
    setSelectedRoleBenefitsIds(newBenefitsArr)

    /* UPDATES NAMES BOX */
    setSelectedRoleBenefitsNames(newBenefits)
  }

  const getBenefitsOpts = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/getBenefits/`, { 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
      })

      const data = await res.json()

      if (data?.success) {
        const benefits = data?.data

        const newBenefits = []
        benefits?.forEach(benefit => {
          newBenefits?.push({ value: benefit?.id, label: benefit?.name })
        })
        setBenefitsOpts(newBenefits)
      }
        
    } catch (error) {
        console.error('There was an error!', error)
    }
  }

  useEffect(() => { getBenefitsOpts() }, [])

  // Basic End

  // Description Start
  // ReactSession.setStoreType("localStorage");
  // Description End

  // Experience Start
  let skillLevelStrings = [];
  let parentString = "";
  let skillLevelString = "";
  let skillString = "";
  let levelMinString = "";
  let tagtext = "";
  let timerid = "";

  const years = [
    { value: 1, label: "1+ Year" },
    { value: 2, label: "2+ Years" },
    { value: 3, label: "3+ Years" },
    { value: 4, label: "4+ Years" },
    { value: 5, label: "5+ Years" },
    { value: 6, label: "6+ Years" },
    { value: 7, label: "7+ Years" },
    { value: 8, label: "8+ Years" },
    { value: 9, label: "9+ Years" },
    { value: 10, label: "10+ Years" },
  ]

  function changeskillPlaceholder(remove, parent) {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      if (remove) {
        $(`${parent} .react-tag-input__input`).attr("placeholder", "");
      } else {
        $(`${parent} .react-tag-input__input`).attr("placeholder", "Skills selected...");
      }
    }, 100);
  }

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateSkills = async () => {
    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.skills?.length; i++) {
      newData.push({ value: interests?.data?.skills[i]?.name, label: interests?.data?.skills[i]?.name });
    }

    // $(".requiredBox").attr("data-skills", JSON.stringify(interests?.data?.skills));

    setSkills(newData);
  }

  useEffect(() => { populateSkills() }, []);

  function notInArray(skillStr, skillArray) {
    // eslint-disable-next-line
    for (const skillLevel of skillArray) {
      if (skillLevel.includes(`${skillStr} -`)) {
        return false;
      }
    }

    return true;
  }

  function addSkill() {
    skillString = $(".requiredBox input[name=skill]").val();
    levelMinString = $(".requiredBox input[name=levelMin]").val();

    if (skillString && levelMinString && notInArray(skillString, experiencieSkillLevels)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...experiencieSkillLevels];
      skillLevelStrings.push(skillLevelString);
      setExperiencieSkillLevels(skillLevelStrings);
      changeskillPlaceholder(1, ".skillsBox");
    }

    // If skill is in array, replace it with the new one
    if (skillString && levelMinString && !notInArray(skillString, experiencieSkillLevels)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...experiencieSkillLevels];
      // eslint-disable-next-line
      for (let i = 0; i < skillLevelStrings.length; i++) {
        if (skillLevelStrings[i].includes(`${skillString} -`)) {
          skillLevelStrings[i] = skillLevelString;
          break;
        }
      }
      setExperiencieSkillLevels(skillLevelStrings);
    }

    $(".requiredBox .react-tag-input").removeClass("error")
  }

  function addOptionalSkill() {
    skillString = $(".optionalBox input[name=skill]").val();
    levelMinString = $(".optionalBox input[name=yearsMin]").val();

    if (skillString && levelMinString && notInArray(skillString, optionalSkillLevels)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...optionalSkillLevels];
      skillLevelStrings.push(skillLevelString);
      setOptionalSkillLevels(skillLevelStrings);
      changeskillPlaceholder(1, ".optionalSkillsBox");
    }

    // If skill is in array, replace it with the new one
    if (skillString && levelMinString && !notInArray(skillString, optionalSkillLevels)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...optionalSkillLevels];
      // eslint-disable-next-line
      for (let i = 0; i < skillLevelStrings.length; i++) {
        if (skillLevelStrings[i].includes(`${skillString} -`)) {
          skillLevelStrings[i] = skillLevelString;
          break;
        }
      }
      setOptionalSkillLevels(skillLevelStrings);
    }
  }

  function removeTag() {
    console.log("remove tag");
  }

  /* Mui hook to read screen size - We use it for small screen rendering */
  const isSmallScreen = useMediaQuery('(max-width: 1201px)')
  // Experience End

  // Questions Start
  // const searchParams = new URLSearchParams(window.location.search);
  // const jobId = searchParams.get('id');

  function showError(text) {
    let timerErrorid = "";
    $(".alertErrorBox div div").text(text);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerErrorid) {
      clearTimeout(timerErrorid);
    }
    timerErrorid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 4000);
  }

  const questionsAddedArrayDefault = [
    { id: 0, value: "" },
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
  ]
  
  const [questionsAddedArray, setQuestionsAddedArray] = useState(questionsAddedArrayDefault);
  function addQuestion() {
    const questionVal = $("input[name=question]").val();
    if (questionVal && $(".questionButton").length <= 5 && questionVal.length <= 500) {
      if ($(".questionButton").length === 5){
        $(".addBox").hide();
      }
      setQuestionsCount($(".questionButton").length + 1);
      const questionsAddedArrayTemp = [...questionsAddedArray]
      // eslint-disable-next-line
      for (const questionAddeTemp in questionsAddedArrayTemp) {
        if (questionsAddedArrayTemp[questionAddeTemp].value === "" && questionsAddedArrayTemp[questionAddeTemp].id) {
          questionsAddedArrayTemp[questionAddeTemp].value = questionVal;
          break;
        }
      }
      setQuestionsAddedArray(questionsAddedArrayTemp)
      $("input[name=question]").val("");
    } else if (questionVal.length > 500) {
      showError("Your question cannot have more than 500 characters")
    }
  }
  
  function removeQuestion(questionToRemove) {
    const questionsAddedArrayTemp = [...questionsAddedArray]
    if (questionsAddedArrayTemp[questionToRemove]) {
      questionsAddedArrayTemp[questionToRemove].value = "";
      setQuestionsAddedArray(questionsAddedArrayTemp)
      setQuestionsCount(questionsCount - 1);
      $(".addBox").show();
    }
  }

  function removeTitleQuestion(questionTitle) {
    // eslint-disable-next-line
    $(".questionBox").each(function( index ) {
      if ($(this).find("input").val() === questionTitle && index >= 0) {
        $(this).remove()
      }
    });
    setQuestionsCount(questionsCount - 1);
    $(".addBox").show();
  }
  // Questions End

  // salary Start
  // Stores wether the job has an hourly rate or not. Freelance positions have hourly rate.
  
  function changeskillPlaceholder2(remove) {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      if (remove) {
        $(".react-tag-input__input").attr("placeholder", "");
      } else {
        $(".react-tag-input__input").attr("placeholder", "Countries selected...");
      }
    }, 100);
  }

  // useEffect(() => {
  //   if (lastApply.countries && lastApply.countries.length) {
  //     const countryTags = [];
  //     // eslint-disable-next-line
  //     for (const countryIndex in lastApply.countries) {
  //       countryTags.push(lastApply.countries[countryIndex].name);
  //     }

  //     // setSkillLevels(countryTags);
  //     loadCountries(false, countryTags);
  //   }

  //   if (lastApply.minSalary && lastApply.maxSalary) {
  //     $(".salaryRange").attr("data-min", lastApply.minSalary).attr("data-max", lastApply.maxSalary);
  //   }
  // }, []);

  const handleChange = (event, newValue) => {
    setSalaryValue(newValue);
    $(".salaryRange").attr("data-min", newValue[0]).attr("data-max", newValue[1]);
  };

  const handleNoMaxSalaryChange = (event, newValue) => {
    setNoMaxSalaryValue(newValue);
    $(".salaryRange").attr("data-min", newValue);
  };

  function notInArray2(skillStr) {
    // eslint-disable-next-line
    for (const skillLevel of skillLevels) {
      if (skillLevel === skillStr) {
        return false;
      }
    }

    return true;
  }

  function addCountry() {
    skillString = $("input[name=countries]").val();
    if (skillString === "0") {
      setSkillLevels(["Anywhere in LATAM"]);
      setCountries([{value: 'United States', label: 'United States'}]);
    } else if (skillString && notInArray2(skillString)) {
        skillLevelStrings = [...skillLevels];
        skillLevelStrings.push(skillString);
        setSkillLevels(skillLevelStrings);
        changeskillPlaceholder2(1);
    }
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", ".skillsBoxSalary .react-tag-input__tag__remove, .skillsBox .react-tag-input__tag__remove, .optionalSkillsBox .react-tag-input__tag__remove, .validateField", function () {
    if ($(this).hasClass("validateField")) {
      $(this).removeClass("error");
    } else {
      // eslint-disable-next-line
      if ($(this).parents(".skillsBoxSalary").length) {
        tagtext = $(this).prev().text();
        if (tagtext === "Anywhere in LATAM") {
          loadCountries(true, null);
        } else {
          skillLevelStrings = [...skillLevels];
          if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
            skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1);
            setSkillLevels(skillLevelStrings);
            if (!skillLevelStrings.length) {
              changeskillPlaceholder2(0);
            }
          }
        }
      } else {
        tagtext = $(this).prev().text();
        skillLevelStrings = [...experiencieSkillLevels];
        parentString = ".skillsBox";

        if ($(this).parents(".optionalSkillsBox").length) {
          skillLevelStrings = [...optionalSkillLevels];
          parentString = ".optionalSkillsBox";
        }

        if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
          skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1);
          if ($(this).parents(".skillsBox").length) {
            setExperiencieSkillLevels(skillLevelStrings);
          }
          if ($(this).parents(".optionalSkillsBox").length) {
            setOptionalSkillLevels(skillLevelStrings);
          }

          if (!skillLevelStrings.length) {
            changeskillPlaceholder(0, parentString);
          }
        }
      }
    }
  });

  function addFavorie() {
    $(".addFavorie").css("display", "none");
    $(".removeFavorie").css("display", "block");
    const templastApply = { ...ReactSession.get("lastApply") };
    templastApply.addAsFavorite = true;
    ReactSession.set("lastApply", templastApply);
  }

  function removeFavorie() {
    $(".removeFavorie").css("display", "none");
    $(".addFavorie").css("display", "block");
    const templastApply = { ...ReactSession.get("lastApply") };
    templastApply.addAsFavorite = false;
    ReactSession.set("lastApply", templastApply);
  }

  const handleFixedSalary = (value) => {
    setFixedSalary(value)
    if (value && isSalaryBasedOnExperience) {
      setIsSalaryBasedOnExperience(false)
    }
  };

  const handleIsSalaryBasedOnExperience = (value) => {
    setIsSalaryBasedOnExperience(value)
    if (value && fixedSalary) {
      setFixedSalary(false)
    }
  };

  // salary End

  return (
    <SuiBox>
      { (loading || benefitsOpts.length === 0 || commitmentOptions.length === 0) ? (
          <SuiBox my={15} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={40} /></SuiBox>
      ) : 
        <>
          <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
            <SuiTypography variant="h5" fontWeight="regular">What role are you looking for?</SuiTypography>
          </SuiBox>

          <SuiBox>
            <Grid container>

              <Grid item xs={12} mb={2}>
                <SuiTypography variant="button" ml={1}>Company</SuiTypography>
                {lastApply.companyName ? (
                  <SuiSelect
                    placeholder={lastApply.companyName}
                    options={companies}
                    name="company"
                    isDisabled
                  />
                ) : (
                  <SuiSelect
                    placeholder="companies"
                    options={companies}
                    name="company"
                    className="validateField"
                  />
                )}
              </Grid>

              <Grid item xs={12} mb={2}>
                <SuiTypography variant="button" ml={1}>Role title</SuiTypography>
                <SuiTypography variant="overline" color="secondary" ml={1}>*Please do not include seniority level in your title</SuiTypography>
                <SuiInput
                  placeholder="i.e. Backend Developer (max 40 characters)"
                  name="name"
                  defaultValue={lastApply.name}
                  key={lastApply.name}
                  inputProps={{ maxLength: 40 }}
                  className="validateField"
                />
              </Grid>

              <Grid item xs={12} mb={2}>
                <SuiTypography variant="button" ml={1}>Subtitle</SuiTypography>
                <SuiTypography variant="overline" color="secondary" ml={1}>*Add a brief elevator pitch for your company or this opportunity (optional)</SuiTypography>
                <SuiInput
                  placeholder="i.e. We are revolutionizing the payments industry! (max 120 characters)"
                  name="subtitle"
                  defaultValue={lastApply.subtitle}
                  key={lastApply.name}
                  inputProps={{ maxLength: 120 }}
                />
              </Grid>

              <Grid item xs={12} mb={2}>
                <SuiTypography variant="button" ml={1} mb={1}>
                    Role seniority
                  </SuiTypography>
                  {lastApply.seniority ? (
                    <SuiSelect
                      placeholder="Select a seniority level"
                      options={seniority}
                      name="seniority"
                      defaultValue={seniority.find(({ value }) => value === lastApply.seniority)}
                      key={lastApply.seniority}
                      className="validateField"
                    />
                  ) : (
                    <SuiSelect
                      placeholder="Select a seniority level"
                      options={seniority}
                      name="seniority"
                      className="validateField"
                    />
                  )}
              </Grid>
              
              <Grid container spacing={3}>
                <Grid item xs={6} mb={2}>
                  <SuiTypography variant="button" ml={1} mb={1}>
                      Role category
                    </SuiTypography>
                    {lastApply.category ? (
                      <SuiSelect
                        placeholder="Select category"
                        options={category}
                        name="category"
                        defaultValue={category.find(({ value }) => value === lastApply.category)}
                        key={lastApply.category}
                        className="validateField"
                      />
                    ) : (
                      <SuiSelect
                        placeholder="Select category"
                        options={category}
                        name="category"
                        className="validateField"
                      />
                    )}
                </Grid>

                <Grid item xs={6} mb={2}>
                  <SuiTypography variant="button" ml={1} mb={1}>
                      Commitment
                    </SuiTypography>

                    {lastApply?.commitment ? (
                      <SuiSelect
                        placeholder="Select time commitment"
                        options={commitmentOptions}
                        name="Commitment"
                        defaultValue={commitmentOptions?.find(({ value }) => value === lastApply?.commitment?.id)}
                        onChange={e => setSelectedRoleCommitment({id: e.value})}
                        key={lastApply?.commitment}
                        className="validateField"
                      />
                    ) : (
                      <SuiSelect
                        placeholder="Select time commitment"
                        options={commitmentOptions}
                        onChange={e => setSelectedRoleCommitment({id: e.value})}
                        name="Commitment"
                        className="validateField"
                      />
                    )}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SuiTypography variant="button" ml={1} mb={1}>
                    Hiring method
                  </SuiTypography>
                  {lastApply.hiringMethod ? (
                    <SuiSelect
                      placeholder="Select contract type"
                      options={method}
                      name="hiringMethod"
                      defaultValue={method.find(({ value }) => value === lastApply.hiringMethod)}
                      key={lastApply.hiringMethod}
                      className="validateField"
                    />
                  ) : (
                    <SuiSelect
                      placeholder="Select contract type"
                      options={method}
                      name="hiringMethod"
                      className="validateField"
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiTypography variant="button" ml={1} mb={1}>
                    Job type
                  </SuiTypography>
                  {lastApply.location ? (
                    <SuiSelect
                      placeholder="Select job type"
                      options={jobtype}
                      name="location"
                      defaultValue={jobtype.find(({ value }) => value === lastApply.location)}
                      key={lastApply.location}
                      className="validateField"
                    />
                  ) : (
                    <SuiSelect
                      placeholder="Select job type"
                      options={jobtype}
                      name="location"
                      className="validateField"
                    />
                  )}
                </Grid>
              </Grid>
              
              {/* BENEFITS PICKER */}
              <Grid item xs={12} mb={2}>
                <SuiBox mt={2} ml={1} mb={0.5}>
                  <SuiTypography variant="button">
                    Job benefits
                  </SuiTypography>
                </SuiBox>
                
                <Grid container xs={12} md={12} spacing={1} className="benefitsBox">
                  <Grid item xs={12} sm={6}>
                    <SuiSelect
                      placeholder="Benefit"
                      options={benefitsOpts}
                      name="benefit"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SuiButton color="info" onClick={addBenefit} fullWidth>Add</SuiButton>
                  </Grid>
                  
                  <Grid item xs={12} md={12} className="benefitsBox">
                    <SuiTagInput
                      placeholder="Benefits selected..."
                      tags={selectedRoleBenefitsNames}
                      size="large"
                      onChange={updateBenefits}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* TAGS PICKER */}
              {canAddTags() && (
                <Grid item xs={12} mb={2}>
                  <SuiBox mt={2} ml={1} mb={0.5}>
                    <SuiTypography variant="button">
                      Optional tags
                    </SuiTypography>
                  </SuiBox>
                  
                  <Grid container xs={12} md={12} spacing={1} className="optionalBox">
                    <Grid item xs={12} sm={6}>
                      <SuiSelect
                        placeholder="Tag"
                        options={tagOptions}
                        name="tag"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SuiButton color="info" onClick={addOptionalTag} fullWidth>Add</SuiButton>
                    </Grid>
                    
                    <Grid item xs={12} md={12} className="optionalTagsBox">
                      <SuiTagInput
                        placeholder="Tags selected..."
                        tags={selectedTagsNames}
                        size="large"
                        onChange={updateTags}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

            </Grid>
          </SuiBox>



          <SuiBox>
            <SuiBox width="100%" textAlign="center" mt={3} mb={3}>
              <SuiTypography variant="h5" fontWeight="regular">Add a detailed description for your job</SuiTypography>
            </SuiBox>

            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
              mb={4}
            >
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Company description</SuiTypography>
              </SuiBox>

              <SuiEditor
                maxLength={5000}
                defaultValue={companyDescription}
                name={bio.name}
                onChange={setCompanyDescription}
                placeholder={bio.placeholder}
                error={errors.bio && touched.bio}
                success={bioV.length > 0 && !errors.bio}
              />

              <SuiTypography
                variant="overline"
                color={companyDescription.length > 5000 ? "error" : "secondary"}
                textAlign="right"
              >
                {companyDescription.length}/5000 chars.
              </SuiTypography>
            </SuiBox>

            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
              mb={4}
            >
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Job description</SuiTypography>
                <SuiTypography variant="overline" color="secondary" ml={1}>(responsibilities, requirements, etc.)</SuiTypography>
              </SuiBox>

              <SuiEditor
                defaultValue={description}
                value={description}
                name="description"
                placeholder="Job description (max 15000 chars.)"
                onChange={setDescription}
                maxLength={15000}
                className="validateField"
              />

              <SuiTypography
                variant="overline"
                color={description.length > 15000 ? "error" : "secondary"}
                textAlign="right"
              >
                {description.length}/15000 chars.
              </SuiTypography>

              <span data-name="description" data-html={description} data-bio={companyDescription} style={{ display: "none" }} >test</span>
            </SuiBox>
          </SuiBox>



          <SuiBox>
            <SuiBox width="100%" textAlign="center" mb={4}>
              <SuiTypography variant="h5" fontWeight="regular">
                {/* Necessary experience to fulfill the role */}
                What skills does this position require?
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button">
                  Required skills
                </SuiTypography>
              </SuiBox>
              <Grid container xs={12} md={12} spacing={1} className="requiredBox">
                <Grid item xs={12} sm={5}>
                  <SuiSelect
                    placeholder="Skill"
                    options={skills}
                    name="skill"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <SuiSelect
                    placeholder="Years"
                    smallFont={isSmallScreen}
                    options={years}
                    name="levelMin"
                  />
                </Grid>
                <Grid item xs={4} sm={3}>
                  <SuiButton color="info" onClick={addSkill} fullWidth>Add</SuiButton>
                </Grid>
                <Grid item xs={12} md={12} className="skillsBox validateField">
                  <SuiTagInput
                    placeholder="Skills selected..."
                    tags={experiencieSkillLevels}
                    size="large"
                    onChange={removeTag}
                  />
                </Grid>
              </Grid>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button">
                  Optional skills
                </SuiTypography>
              </SuiBox>
              <Grid container xs={12} md={12} spacing={1} className="optionalBox">
                <Grid item xs={12} sm={5}>
                  <SuiSelect
                    placeholder="Skill"
                    options={skills}
                    name="skill"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <SuiSelect
                    placeholder="Years"
                    smallFont={isSmallScreen}
                    options={years}
                    name="yearsMin"
                  />
                </Grid>
                <Grid item xs={4} sm={3}>
                  <SuiButton color="info" onClick={addOptionalSkill} fullWidth>Add</SuiButton>
                </Grid>
                <Grid item xs={12} md={12} className="optionalSkillsBox">
                  <SuiTagInput
                    placeholder="Skills selected..."
                    tags={optionalSkillLevels}
                    size="large"
                    onChange={removeTag}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </SuiBox>

          <SuiBox>
            <SuiBox width="100%" textAlign="center" mb={4} mt={6}>
              <SuiTypography variant="h5" fontWeight="regular">
                Please enter any pre-screening questions you would like candidates to answer (Optional)
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button">
                  Add up to five pre-screening questions (optional):
                </SuiTypography>
              </SuiBox>
              <Grid container>
                {/*  */}
                <Grid container xs={12} spacing={1} mb={1} className="addBox">
                  <Grid item xs={10}>
                    <SuiInput placeholder="Type your question here" name="question" inputProps={{ maxLength: 500 }}/>
                  </Grid>
                  <Grid item xs={2} >
                    <SuiButton color="info" onClick={addQuestion} fullWidth>Add</SuiButton>
                  </Grid>
                </Grid>
                { lastApply && lastApply.questions && lastApply.questions.length ? (<>
                  {lastApply.questions.map(question => (
                    <Grid container xs={12} spacing={1} mb={1} className="questionBox">
                      <Grid item xs={10}>
                        <SuiInput placeholder="..." defaultValue={question.title} disabled />
                      </Grid>
                      <Grid item xs={2} >
                        <SuiButton color="error" variant="outlined" onClick={() => removeTitleQuestion(question?.title)} className="questionButton" name="questionValue" fullWidth>Remove</SuiButton>
                      </Grid>
                    </Grid>
                  ))}
                </>) : null }
                { lastApply && lastApply.jobQuestions && lastApply.jobQuestions.length ? (<>
                  {lastApply.jobQuestions.map(question => (
                    <Grid container xs={12} spacing={1} mb={1} className="">
                      <Grid item xs={10}>
                        <SuiInput placeholder="..." defaultValue={question.title} disabled />
                      </Grid>
                    </Grid>
                  ))}
                </>) : null }
                { questionsAddedArray && questionsAddedArray.length ? (<>
                  {questionsAddedArray.map(questionsAdded => (
                    <>
                      { questionsAdded.value && questionsAdded.id ? (
                        <Grid container xs={12} spacing={1} mb={1} className="questionBox">
                          <Grid item xs={10}>
                            <SuiInput placeholder="..." disabled defaultValue={questionsAdded.value} />
                          </Grid>
                          <Grid item xs={2} >
                            <SuiButton color="error" variant="outlined" onClick={() => removeQuestion(questionsAdded.id)} className="questionButton" name="questionValue" fullWidth>Remove</SuiButton>
                          </Grid>
                        </Grid>
                      ) : null }
                    </>
                  ))}
                </>) : null }
                <Grid container xs={12} spacing={1} mb={1} className="questionBox" style={{ opacity: 0 }}>
                  <Grid item xs={10}>
                    <SuiInput placeholder="..." disabled />
                  </Grid>
                  <Grid item xs={2} >
                    <SuiButton color="error" variant="outlined" onClick={() => removeQuestion(this)} className="questionButton" name="questionValue" fullWidth>Remove</SuiButton>
                  </Grid>
                </Grid>
              </Grid>
            </SuiBox>
          </SuiBox>


          <SuiBox>
            <SuiBox width="100%" textAlign="center" mb={4}>
              <SuiTypography variant="h5" fontWeight="regular">
                How much are you offering for this job?
                <SuiBox textAlign="right" mb={4} style={{ float: "right"  }}>
                  <SuiTypography variant="h6" fontWeight="bold">
                    Add to Favorites
                  </SuiTypography>
                  {lastApply.addAsFavorite ? (
                    <StarBorderIcon className="addFavorie" style={{ width: "30px", height: "30px", float: "right", marginRight: "40px", cursor: "pointer", display: "none"  }} onClick={addFavorie} />
                  ) : (
                    <StarBorderIcon className="addFavorie" style={{ width: "30px", height: "30px", float: "right", marginRight: "40px", cursor: "pointer" }} onClick={addFavorie} />
                  )}
                  {lastApply.addAsFavorite ? (
                    <StarIcon className="removeFavorie" style={{ width: "30px", height: "30px", float: "right", marginRight: "40px", cursor: "pointer", color: "#59d657" }} onClick={removeFavorie} />
                  ) : (
                    <StarIcon className="removeFavorie" style={{ width: "30px", height: "30px", float: "right", marginRight: "40px", cursor: "pointer", color: "#59d657", display: "none" }} onClick={removeFavorie} />
                  )}
                </SuiBox>
                
              </SuiTypography>
            </SuiBox>

            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="100%"
              height="100%"
              mt={4}
              mb={5}
            >
              <SuiBox
                mb={1}
                lineHeight={0}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {hasHourlyRate ?
                  <SuiTypography variant="button">What is your hourly rate offering for this position?</SuiTypography>
                  :
                  <SuiTypography variant="button">What is your monthly salary offering for this position?</SuiTypography>
                }
                
                <SuiBox>
                  <Checkbox checked={isSalaryBasedOnExperience} onChange={() => handleIsSalaryBasedOnExperience(prev => !prev)}/>
                  <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Salary based on experience</SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox sx={{ marginRight: "15px", textAlign: "right" }}>
                <Checkbox checked={fixedSalary} onChange={() => handleFixedSalary(prev => !prev)}/>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Fixed Salary</SuiTypography>
              </SuiBox>

              {!isSalaryBasedOnExperience && (
                <Grid container spacing={2} className="salaryRange" data-min="0" data-max="20000">
                  <Grid item xs={12}>
                    <SuiBox mt={5}>
                        {showMaxSalary && !fixedSalary ?
                          <>
                            {hasHourlyRate ? 
                              <Slider
                                getAriaLabel={() => 'Salary range'}
                                value={salaryValue}
                                onChange={handleChange}
                                getAriaValueText={valuetext}
                                components={{ ValueLabel: ValueLabelComponent }}
                                valueLabelDisplay="on"
                                step={1}
                                min={1}
                                max={100}
                              />
                            :
                              <Slider
                                getAriaLabel={() => 'Salary range'}
                                value={salaryValue}
                                onChange={handleChange}
                                getAriaValueText={valuetext}
                                components={{ ValueLabel: ValueLabelComponent }}
                                valueLabelDisplay="on"
                                step={100}
                                min={100}
                                max={20000}
                              />
                            }
                          </>
                          :
                          <>
                            {hasHourlyRate ? 
                              <CustomSlider
                                getAriaLabel={() => 'Salary range'}
                                value={noMaxSalaryValue}
                                onChange={handleNoMaxSalaryChange}
                                getAriaValueText={valuetext}
                                components={{ ValueLabel: ValueLabelComponent }}
                                valueLabelDisplay="on"
                                step={1}
                                min={1}
                                max={100}
                                // edit styles so the mui slider highlights from the right side instead than from the left side
                              />
                            :
                              <CustomSlider
                                getAriaLabel={() => 'Salary range'}
                                value={noMaxSalaryValue}
                                onChange={handleNoMaxSalaryChange}
                                getAriaValueText={valuetext}
                                components={{ ValueLabel: ValueLabelComponent }}
                                valueLabelDisplay="on"
                                step={100}
                                min={100}
                                max={20000}
                              />
                            }
                          </>
                        }
                    </SuiBox>
                  </Grid>
                </Grid>
              )}

              <SuiBox
                mb={3}
                lineHeight={0}
                width="100%"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >          
                <SuiBox>
                  <Checkbox checked={showMaxSalary} onChange={() => setShowMaxSalary(prev => !prev)}/>
                  <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Include max salary</SuiTypography>
                </SuiBox>
              </SuiBox>

            </SuiBox>
            <SuiBox mt={2} style={{ "zIndex": 2 }}>
              <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button">
                  Hire from:
                </SuiTypography>
              </SuiBox>
              <Grid container xs={12} md={12} spacing={1}>
                <Grid item xs={8}>
                  <SuiSelect
                    placeholder="Select Countries"
                    options={countries}
                    name="countries"
                  />
                </Grid>
                <Grid item xs={4} >
                  <SuiButton color="info" onClick={addCountry} fullWidth>Add</SuiButton>
                </Grid>
                <Grid item xs={12} md={12} className="skillsBoxSalary">
                  <SuiTagInput
                    placeholder="Countries selected..."
                    tags={skillLevels}
                    onChange={removeTag}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </SuiBox>
          <br/>
          { lastApply ? (
            <Grid container xs={12} spacing={1} mb={1} className="">
              <SuiBox ml={1.5} mb={0.5}>
                <SuiTypography variant="button">
                  English Required: Candidates with an English level of 6 or lower will be auto-declined.
                </SuiTypography>
              </SuiBox>
              <Grid item xs={9}>
                <SuiInput placeholder="..." defaultValue="English required" disabled />
              </Grid>
              <Grid item xs={3}>
                {englishRequiredSelected !== "" ? (
                   <SuiSelect
                     placeholder="Yes"
                     options={englishRequired}
                     name="englishRequired"
                     defaultValue={englishRequired?.find(({ value }) => value === englishRequiredSelected)}
                     onChange={e => setEnglishRequiredSelected(e.value)}
                     key={englishRequiredSelected}
                   />
                 ) : (
                   <SuiSelect
                     placeholder="Yes"
                     options={englishRequired}
                     onChange={e => setEnglishRequiredSelected(e.value)}
                     name="englishRequired"
                   />
                 )}
              </Grid>
            </Grid>
          ) : null }
        </>
      }
    </SuiBox>
  );
}

OnePage.defaultProps = {
  firstView: false,
}

OnePage.propTypes = {
  firstView: PropTypes.bool,
  tagOptions: PropTypes.array.isRequired, // eslint-disable-line
  selectedTagsNames: PropTypes.array.isRequired, // eslint-disable-line
  setSelectedTagsNames: PropTypes.func.isRequired,
  setIsSalaryBasedOnExperience: PropTypes.func.isRequired,
  setSelectedRoleCommitment: PropTypes.func.isRequired,
  selectedRoleBenefitsIds: PropTypes.array.isRequired, // eslint-disable-line
  setSelectedRoleBenefitsIds: PropTypes.func.isRequired,
  commitmentOptions: PropTypes.array.isRequired, // eslint-disable-line
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isSalaryBasedOnExperience: PropTypes.bool.isRequired,
  skillLevels: PropTypes.array.isRequired, // eslint-disable-line
  setSkillLevels: PropTypes.func.isRequired,
  fullCountriesList: PropTypes.array.isRequired, // eslint-disable-line
  selectedRoleCommitment: PropTypes.object.isRequired, // eslint-disable-line
  showMaxSalary: PropTypes.bool.isRequired,
  setShowMaxSalary: PropTypes.func.isRequired,
}

export default OnePage